import { Message } from '../model/message';
import { PaginationInformation } from '../model/pagination-information';
import { GraphResultProcessor } from './graph-result-processor';

export class PagedGraphResultProcessor extends GraphResultProcessor {

  protected processUnknownMessage(msg: Message, msgData: any): PaginationInformation {
    if (isPaginationInformation(msg)) {
      return this.processPaginationInformation(msgData);
    } else {
      return super.processUnknownMessage(msg, msgData);
    }
  }

  private processPaginationInformation(msgData: any): PaginationInformation {
    return new PaginationInformation(msgData.scopeId, msgData.previousElementCount, msgData.elementsFollow > 0, msgData.totalElementCount);
  }
}

export function isPaginationInformation(msg: Message): boolean {
  return msg.data ? msg.data.type === 'PaginationInformation' : false;
}
