import { defer, from, Observable, of, timer } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { catchError, delayWhen, repeatWhen, switchMap } from 'rxjs/operators';

import { CelumPropertiesProvider } from '../../core/configuration/celum-properties-provider';

export enum ConnectionState {
  CONNECTION_OPEN, CONNECTION_CLOSED, CONNECTION_LOST, CONNECTION_RESTORED
}

export class ConnectionChecker {

  public checkForOnlineState(additionalCheck?: () => Promise<boolean>): Observable<boolean> {
    return this.ping(additionalCheck);
  }

  protected ping(additionalCheck?: () => Promise<boolean>): Observable<boolean> {
    const url = CelumPropertiesProvider.properties.pingAddress;

    return this.executeCall(url)
               .pipe(
                 switchMap((res: AjaxResponse<any>) => {
                   if (res.status === 200) {
                     if (additionalCheck) {
                       return from(additionalCheck());
                     } else {
                       return of(true);
                     }
                   } else {
                     return of(false);
                   }
                 }),
                 catchError(err => {
                   if (err.status === 401 && CelumPropertiesProvider.properties.redirectToUrlOn401.length > 0) {
                     window.location.href = CelumPropertiesProvider.properties.redirectToUrlOn401;
                     return of(false);
                   } else if (err.status === 403 && CelumPropertiesProvider.properties.redirectToSelfOn403) {
                     // noinspection SillyAssignmentJS
                     window.location.href = window.location.href;
                     return of(false);
                   } else {
                     console.error('Error executing ping on ' + url, err.status);
                     return of(false);
                   }
                 }),
                 repeatWhen(next => next.pipe(delayWhen(() => defer(() => timer(CelumPropertiesProvider.properties.pingInterval)))))
               );
  }

  protected executeCall(url: string): Observable<AjaxResponse<any>> {
    return ajax({
                  url,
                  async: true,
                  method: 'GET',
                  headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                  }
                });
  }
}
