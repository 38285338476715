type ScriptElementOptions = Pick<HTMLScriptElement, 'defer'>;

/*
 * Add a script tag with the {@param src} as content to the DOM header.
 * Any additionally provided options via {@param options} will be applied to the script element.
 * @return created script dom element.
 */
export function addScript(src: string, options?: Partial<ScriptElementOptions>): HTMLScriptElement {
  const script = document.createElement('script');
  script.src = src;
  Object.entries(options || {}).forEach(([key, value]) => script[key as keyof ScriptElementOptions] = value);
  return document.head.appendChild(script);
}

/**
 * Append a search param to the given url.
 * If there are already search params, the new param will be appended with an ampersand.
 * If there are no search params, the new param will be appended with a question mark.
 * @return a copy of the given url with the new param appended.
 */
export function appendUrlSearchParam(url: string, param: string, value: string): string {
  const urlObj = new URL(url);
  urlObj.searchParams.append(param, value);
  return urlObj.toString();
}
