import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Replaces line breaks in text with <code>&lt;br&gt;</code> tags to be displayed in HTML.
 * Before using this pipe, consider utilizing the css white-space feature  https://developer.mozilla.org/en-US/docs/Web/CSS/white-space instead (eg. pre)
 *
 * Sanitization: By default, the result is sanitized and returned as {@link SafeHTML}. You can also pass "false" as second parameter to prevent the
 * sanitization and receive a simple, probably insecure, string.
 */
@Pipe({
        name: 'parseLineBreaks',
        pure: true
      })
export class ParseLineBreaksPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  public transform(text: string, sanitize?: true): SafeHtml;
  public transform(text: string, sanitize: false): string;
  public transform(text: string, sanitize: boolean = true): string | SafeHtml {
    if (!text) {
      return text;
    }

    const value = ParseLineBreaksPipe.parseLineBreaks(text);

    if (sanitize) {
      const sanitizedText: string = this.sanitizer.sanitize(SecurityContext.HTML, value);
      return this.sanitizer.bypassSecurityTrustHtml(sanitizedText);
    }

    return value;
  }

  public static parseLineBreaks(value: string): string {
    const text = value.replace(/(\r\n|\n|\r)/gm, '<br>');
    return ParseLineBreaksPipe.removeLineBreaks(text);
  }

  /**
   * Removes "additional" line breaks. If a text contains multiple line breaks after each other you cannot simply replace each of them with <br>. If you do
   * that, the displayed HTML will contain one additional line break. Therefore, remove one line break for every occurrence of multiple breaks in a row.
   *
   * E.g. a text like so:
   * "hello
   *
   * my
   * friend"
   *
   * Will be "transformed" to:
   * "hello<br><br><br>my<br>friend"
   *
   * Which would be displayed as follows on the page:
   * "hello
   *
   *
   * my
   * friend"
   *
   * Therefore, this method will remove the superfluous line break(s).
   */
  private static removeLineBreaks(text: string): string {
    const parts = text.split('<br>');

    const removedBreaks = parts.filter((entry, index) => {
      if (entry === '') {
        let breakAfter = false;
        let breakBefore = false;

        if (index + 1 < parts.length) {
          breakAfter = parts[index + 1] === '';
        }

        if (index > 0) {
          breakBefore = parts[index - 1] === '';
        }

        return !(breakBefore && !breakAfter);
      }

      return true;
    });

    return removedBreaks.join('<br>');
  }

}
