import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';

import { CommunicationException } from '../../communication/model/error';
import { CelumPropertiesProvider } from '../../core/configuration/celum-properties-provider';

export enum LogLevel {
  TRACE = 0, DEBUG = 1, INFO = 2, WARN = 3, ERROR = 4
}

export interface LogMessage {
  level: LogLevel;
  args: any[];
  time: number;
}

/**
 * Utility that overwrites console logging methods once {@link init} is called.
 * Exposes {@link LogMessage}s in a regular interval defined by {@link CelumPropertiesProvider.properties.uiLogsReportInterval} with {@link getLogMessages}
 * method. Allows to specify independently for which {@link LogLevel} messages are printed to the console and for which {@link LogLevel} logs are potentially
 * sent to the server (grab those messages via {@link getLogMessages}).
 */
export class Logger {

  private static preservedConsoleLog = console.log;
  private static preservedConsoleError = console.error;
  private static preservedConsoleWarn = console.warn;
  private static preservedConsoleDebug = console.debug;
  private static preservedConsoleTrace = console.trace;
  private static preservedConsoleInfo = console.info;

  private static consoleLogLevel: LogLevel = CelumPropertiesProvider.properties.consoleLogLevel;
  private static serverLogLevel: LogLevel = CelumPropertiesProvider.properties.logLevel;
  private static logMessages$ = new BehaviorSubject<LogMessage[]>([]);
  private static messages: LogMessage[] = [];
  private static logSubscription: Subscription;

  /**
   * Call this to overwrite the console methods and keep messages for sending them to the server. Call {@link getLogMessages} to get the log messages meant to
   * be sent to the server.
   */
  public static init(): void {
    // reset messages and observable - just in case
    this.messages = [];
    this.logMessages$.next([]);

    this.overwriteMethods();

    this.logSubscription = interval(CelumPropertiesProvider.properties.uiLogsReportInterval).subscribe(() => {
      const messages = [...this.messages];
      this.messages = [];

      if (messages.length) {
        this.logMessages$.next(messages);
      }
    });
  }

  /**
   * Revert console logging function overwrites and stop exposing logs.
   */
  public static stop(): void {
    this.logSubscription && this.logSubscription.unsubscribe();

    console.log = this.preservedConsoleLog;
    console.error = this.preservedConsoleError;
    console.warn = this.preservedConsoleWarn;
    console.debug = this.preservedConsoleDebug;
    console.trace = this.preservedConsoleTrace;
    console.info = this.preservedConsoleInfo;
  }

  /**
   * Retrieve array of {@link LogMessage}s every {@link CelumPropertiesProvider.properties.uiLogsReportInterval} ms - if something new happened.
   */
  public static getLogMessages(): Observable<LogMessage[]> {
    return this.logMessages$.asObservable();
  }

  /**
   * Set the {@link LogLevel} for the logs that are send to the server.
   * @param logLevel the new log level
   */
  public static setServerLogLevel(logLevel: LogLevel): void {
    this.serverLogLevel = logLevel;
  }

  /**
   * Set the {@link LogLevel} for the logs that are printed to the console.
   * @param logLevel the new log level
   */
  public static setConsoleLogLevel(logLevel: LogLevel): void {
    this.consoleLogLevel = logLevel;
  }

  private static overwriteMethods(): void {
    console.info = (...args: any[]) => {
      this.print(LogLevel.INFO, args, this.preservedConsoleInfo);
    };
    console.log = (...args: any[]) => {
      this.print(LogLevel.INFO, args, this.preservedConsoleLog);
    };
    console.debug = (...args: any[]) => {
      this.print(LogLevel.DEBUG, args, this.preservedConsoleDebug);
    };
    console.warn = (...args: any[]) => {
      this.print(LogLevel.WARN, args, this.preservedConsoleWarn);
    };
    console.error = (...args: any[]) => {
      this.handleAndPrintError(args);
    };
    console.trace = (...args: any[]) => {
      this.print(LogLevel.TRACE, args, this.preservedConsoleTrace);
    };
  }

  private static handleAndPrintError(args: any): void {
    const logLevel = LogLevel.ERROR;

    if (this.consoleLogLevel <= logLevel) {
      this.preservedConsoleError(...args);
    }

    if (this.serverLogLevel <= logLevel) {
      try {
        let error: any;
        let message = '';
        let errorMsg = '';

        if (args.length === 1) {
          error = args[0];
        } else {
          message = args[0];
          error = args[1];
        }

        if (error instanceof Error) {
          errorMsg = this.getErrorMessageFromError(error);

          // make sure that there is a message - if none was provided
          if (!message) {
            message = error.toString();
          }
        } else if (error instanceof CommunicationException) {
          message += error.messageKey;
          errorMsg = this.getErrorMessageFromError(error.error);
        } else {
          message += error;
        }

        this.rememberMessage(logLevel, [message, errorMsg]);
      } catch (e) {
        // just to make to not break anything if we screwed up here...
        this.preservedConsoleError('Logger: we screwed up :(', e, ...args);
      }
    }
  }

  private static getErrorMessageFromError(error: any): string {
    let errorMsg: string;

    if (error.stack) {
      const stacktrace: string[] = error.stack.split('\n');

      errorMsg = stacktrace.join('\n');
    } else {
      errorMsg = error.toString();
    }

    return errorMsg;
  }

  private static print(logLevel: LogLevel, args: any[], logFn: (...args: any[]) => void): void {
    if (this.consoleLogLevel <= logLevel) {
      logFn(...args);
    }
    if (this.serverLogLevel <= logLevel) {
      this.rememberMessage(logLevel, args);
    }
  }

  private static rememberMessage(logLevel: LogLevel, args: any[]): void {
    this.messages.push(({
      level: logLevel,
      args,
      time: Date.now()
    }));
  }
}

/**
 * Used to allow changing the console log level from the console during runtime.
 */
// @ts-ignore
window.setConsoleLogLevel = (logLevel: LogLevel) => {
  Logger.setConsoleLogLevel(logLevel);
};

/**
 * Used to allow changing the server log level from the console during runtime.
 */
// @ts-ignore
window.setServerLogLevel = (logLevel: LogLevel) => {
  Logger.setServerLogLevel(logLevel);
};
