export interface ValidationResult {
  messageKey: string;
  params: {
    [key: string]: any
  };
}

export interface HintFn<VALUE_TYPE> {
  identifier: string;

  evaluate(value: VALUE_TYPE): ValidationResult;
}

// shows the defined message - always
export function customText(messageKey: string, params?: object): HintFn<any> {

  if (!messageKey || messageKey.length === 0) {
    throw Error('hints.ts - messageKey must be a valid string!');
  }

  return {
    identifier: 'customText',
    evaluate(_: any): ValidationResult {
      return {
        messageKey,
        params: params || {}
      };
    }
  };
}

// basic maxLengthReached hint - for shorter input fields
// shows a message if maximum number of characters is reached
// tslint:disable-next-line:no-shadowed-variable
export function maxLength(maxLength: number): HintFn<string> {
  if (maxLength <= 0) {
    throw Error('hints.ts - maxLength must be > 0!');
  }

  return {
    identifier: 'maxLength',
    evaluate(value: string): ValidationResult {
      if (value && value.length >= maxLength) {
        return {
          messageKey: 'INPUT_HINTS.MAX_LENGTH',
          params: { maxLength }
        };
      }
      return null;
    }
  };
}

// extended maxLength functionality - for longer input fields
// shows information how many characters can still be entered, starting from given percentage. if 0 chars left, show maxLengthReached message
// tslint:disable-next-line:no-shadowed-variable
export function remainingLength(maxLength: number, threshold?: number): HintFn<string> {
  // tslint:disable-next-line:no-parameter-reassignment
  threshold = threshold || 20; // default value

  if (maxLength <= 0) {
    throw Error('hints.ts - maxLength must be > 0!');
  }
  if (threshold < 0 || threshold > 100) {
    throw Error('hints.ts - threshold (percentage) must be valid (0-100)');
  }

  const evaluatedThreshold: number = threshold === 0 ? 0 : maxLength - (maxLength / 100) * threshold;

  return {
    identifier: 'remainingLength',
    evaluate(value: string): ValidationResult {
      if (value && value.length < maxLength) {

        const remaining: number = maxLength - value.length;

        if (remaining > 0 && value.length >= evaluatedThreshold) {
          return {
            messageKey: 'INPUT_HINTS.REMAINING_CHARS',
            params: { remaining }
          };
        }
      } else if (value && value.length >= maxLength) {
        return {
          messageKey: 'INPUT_HINTS.MAX_LENGTH',
          params: { maxLength }
        };
      }
      return null;
    }
  };
}

export class Hints {
  public static customText: (messageKey: string, params?: object) => any = customText;
  public static maxLength: (length: number) => any = maxLength;
  public static remainingLength: (length: number, threshold?: number) => any = remainingLength;
}
