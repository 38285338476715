import { createMapFromObject } from '../util/data-util';
import { Entity } from './entity';
import { EntityType } from './entity-type';

/**
 * Information object holding the information of the last loaded date (time ms) of the whole entity and various specific properties of the entity.
 */
export interface LastLoaded {
  /**
   * When was the entity with its basic attributes last loaded.
   */
  basicAttributes: number;
  /**
   * When specific optional attributes were last loaded.
   */
  optionalAttributes: { [key: string]: number };
  /**
   * When specific permissions were last loaded.
   */
  permissions: { [key: string]: number };
  /**
   * When specific detail counts were last loaded.
   */
  detailCounts: { [key: string]: number };
  /**
   * When specific relations were last loaded.
   */
  relations: { [key: string]: number };
}

export class GraphRelation implements Entity {

  // tslint:disable-next-line:variable-name
  public _class = 'com.celum.platform.entitydatamodel.dto.Relation';

  public attributes = new Set<string>();

  public entityType: EntityType;

  constructor(public id: string, public typeKey: string, public fromId: string, public toId: string) {
  }
}

export class GraphEntity<PERM extends Map<string, any> = Map<string, any>> implements Entity {

  // tslint:disable-next-line:variable-name
  public _class = 'com.celum.platform.entitydatamodel.dto.Entity';

  public attributes = new Set<string>();
  public optionalAttributes = new Set<string>();
  public partialAttributes = new Set<string>(); // properties that were only partially sent form BE
  public partialAttributeMergeFunctions = new Map<string, (prev: any, curr: any) => any>();

  // key: typeKey, value: relation list id
  public relations: Map<string, string> = new Map<string, string>();
  public knownRelationTypes: Set<string> = new Set<string>();
  public detailCounts = new Map<string, number>();
  public permissions: PERM;
  public entityType: EntityType;
  public knownDetailCountIdentifier: Set<string> = new Set<string>();

  constructor(public id: string, public typeKey: string, detailCounts?: { [key: string]: number } | Map<string, number>,
              permissions?: { [key: string]: any } | PERM) {
    if (detailCounts) {
      if (detailCounts instanceof Map) {
        this.detailCounts = detailCounts;
      } else {
        this.detailCounts = createMapFromObject(detailCounts);
      }
    }

    if (permissions) {
      if (permissions instanceof Map) {
        this.permissions = permissions;
      } else {
        this.permissions = createMapFromObject(permissions) as PERM;
      }
    } else {
      this.permissions = new Map() as PERM;
    }
  }
}

/***
 * Function to register an attribute on an entity which might be received partially
 * @param entity on which the attribute should be registered
 * @param name of the attribute
 * @param mergeFunction which will be called if there is already a value and a new partial value comes in
 */
export function registerPartialAttribute<E extends GraphEntity, N extends keyof E & string>(entity: E, name: N, mergeFunction: (prev: E[N], next: E[N]) => E[N])
  : void {
  entity.partialAttributeMergeFunctions.set(name, mergeFunction);
}

/**
 * Function to check if a specific property on an entity is only partially loaded
 * @param entity for which the property should be checked
 * @param name of the property
 */
export function isPartiallyLoaded<E extends GraphEntity>(entity: E, name: keyof E & string): boolean {
  return entity?.partialAttributes.has(name) ?? false;
}
