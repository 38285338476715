import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'celum-auth-logout-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
  standalone: true
})
export class LogoutComponent implements OnInit {
  constructor(private msal: MsalService) {}
  public ngOnInit(): void {
    this.msal.logoutRedirect({ onRedirectNavigate: () => false });
  }
}
