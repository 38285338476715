export class Optional<T> {

  constructor(private value: T, private hasValue: boolean = true) {

  }

  public get(): T {
    if (!this.hasValue) {
      throw new Error('Optional is empty!');
    }

    return this.value;
  }

  public isPresent(): boolean {
    return this.hasValue;
  }

  public orElse(elseValue: T): T {
    if (this.isPresent()) {
      return this.value;
    } else {
      return elseValue;
    }
  }

  public static of<T>(value: T): Optional<T> {
    return new Optional(value);
  }

  public static ofNull<T>(): Optional<T> {
    return new Optional(null, false);
  }
}
