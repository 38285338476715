import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2 } from '@angular/core';

/**
 * This directive emits clicks that are processed inside of an iframe.
 *
 * Usage:
 *
 * <iframe iframeTracker></iframe>
 *
 * Taken and adapted from: https://gist.github.com/micdenny/db03a814eaf4cd8abf95f77885d9316f
 */
@Directive({
             selector: '[iframeTracker]'
           })
export class IframeTrackerDirective implements OnInit {

  @Output() public readonly iframeClick = new EventEmitter<ElementRef>();

  private iframeMouseOver: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.renderer.listen(window, 'blur', () => this.onWindowBlur());
  }

  /* Special handling for mouseover and mouseout events is necessary to correctly support
   detection of sequential clicks on the iframe */

  @HostListener('mouseover')
  public onIframeMouseOver(): void {
    this.iframeMouseOver = true;
    this.resetFocusOnWindow();
  }

  @HostListener('mouseout')
  public onIframeMouseOut(): void {
    this.iframeMouseOver = false;
    this.resetFocusOnWindow();
  }

  private onWindowBlur(): void {
    if (this.iframeMouseOver) {
      this.resetFocusOnWindow();
      this.iframeClick.emit(this.el);
    }
  }

  private resetFocusOnWindow(): void {
    setTimeout(() => {
      window.focus();
    }, 100);
  }

}