import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { RouterOutlet } from '@angular/router';
import { combineLatest, filter, switchMap, take } from 'rxjs';

import { AuthService, TenantService, UserService } from '@celum/authentication';
import { PortalStorageKey } from '@celum/portals/domain';
import { ConfigurationResolver } from '@celum/portals/shared';
import { TrackingService, TRACKING_SERVICE } from '@celum/shared/domain';

@Component({
  standalone: true,
  imports: [RouterOutlet],
  selector: 'por-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { disableTooltipInteractivity: true, showDelay: 500 }
    },
    {
      provide: MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { showDelay: 500, disableTooltipInteractivity: true }
    }
  ]
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private configuration: ConfigurationResolver,
    private tenantService: TenantService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) {
    // We need this information later to cross-check the user tenant with the portal tenant and only track the user if they are matching.
    // For public portals this could diverge in case the user is logged in with protected portal in tenant A and switches to public portal in tenant B
    // The removing of the tenant information below is necessary for authentication reasons, and we don't know the portal tenant yet, so we need to store it.
    localStorage.setItem(PortalStorageKey.LAST_TENANT_ID, this.tenantService.getCurrentTenantId());
    // Remove the last stored tenant. It needs to be stored only for the current session, so we have to make sure that there is none at startup
    this.tenantService.removeTenant();
    this.trackingService.init();
  }

  public ngOnInit(): void {
    // Load the current user for portals that require authentication. Make sure to wait until the portal configuration is loaded, else the call will fail!
    combineLatest([this.authService.isAuthenticated$, this.configuration.configuration$])
      .pipe(
        filter(([isAuthenticated, configuration]) => isAuthenticated && configuration?.protected),
        take(1),
        // TODO: Handle UserInfoLoadingError here (by e.g. using the AppErrorHandler)
        switchMap(() => this.userService.loadCurrentUser())
      )
      .subscribe();
  }
}
