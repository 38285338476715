import { differenceInCalendarDays, format as dateFormat, formatISO, isAfter, Locale, parse, parseISO } from 'date-fns';
import { de, enUS, fr } from 'date-fns/locale';

export type DateTimeFormats = { [key: string]: { date: string, time: string } };

/**
 * DateUtil for formatting dates according to either provided formats or defaults.
 * If you need to rely on application wide custom formats (e.g. for Nova), use {@link DateService} instead!
 */
export class DateUtil {

  public static FORMATS = {
    DATE: {
      NUMERIC: 'P', // 05/02/1997 (just an example - display of numeric date depends on configured format)
      NAMED: 'PPP', // May 2nd, 1997
      NAMED_SHORT: 'PP', // Apr 2,1997
    }
  };

  public static readonly DEFAULT_DATE_FORMAT_EN = 'dd/MM/yyyy';
  public static readonly DEFAULT_DATE_FORMAT_DE = 'dd.MM.yyyy';
  public static readonly DEFAULT_DATE_FORMAT_FR = 'dd/MM/yyyy';

  public static readonly DEFAULT_TIME_FORMAT_EN = 'hh:mm a';
  public static readonly DEFAULT_TIME_FORMAT_DE = 'HH:mm';
  public static readonly DEFAULT_TIME_FORMAT_FR = 'HH:mm';

  public static readonly DEFAULT_FORMATS = {
    en: {
      date: DateUtil.DEFAULT_DATE_FORMAT_EN,
      time: DateUtil.DEFAULT_TIME_FORMAT_EN
    },
    de: {
      date: DateUtil.DEFAULT_DATE_FORMAT_DE,
      time: DateUtil.DEFAULT_TIME_FORMAT_DE
    },
    fr: {
      date: DateUtil.DEFAULT_DATE_FORMAT_FR,
      time: DateUtil.DEFAULT_TIME_FORMAT_FR
    }
  };

  public static getStartOfDay(date?: number): Date {
    let startOfDay: number;
    if (date) {
      startOfDay = new Date(date).setHours(0, 0, 0, 0);
    } else {
      startOfDay = new Date().setHours(0, 0, 0, 0);
    }
    return new Date(startOfDay);
  }

  public static getEndOfDay(date?: number): Date {
    let endOfDay: number;
    if (date !== undefined) {
      endOfDay = new Date(date).setHours(23, 59, 59, 999);
    } else {
      endOfDay = new Date().setHours(23, 59, 59, 999);
    }
    return new Date(endOfDay);
  }

  public static getFormattedDateNumeric(date: number, locale: string): string {
    return dateFormat(date, DateUtil.getFormat(locale));
  }

  public static getFormattedDate(date: number | Date, format: string, locale: string): string {
    return dateFormat(date, format, { locale: DateUtil.mapStringToLocale(locale) });
  }

  public static shiftDateByDays(date: Date, days: number): number {
    return date.setUTCDate(date.getUTCDate() + days);
  }

  public static handleDateFormat(date: Date, format: string, formats: DateTimeFormats, locale: string, showTime?: boolean): string {
    if (format === DateUtil.FORMATS.DATE.NUMERIC) {
      return dateFormat(date, DateUtil.getFormat(locale, formats, showTime));
    } else if (format === DateUtil.FORMATS.DATE.NAMED || format === DateUtil.FORMATS.DATE.NAMED_SHORT) {
      return dateFormat(date, showTime ? DateUtil.addTimeToGivenFormat(format, locale, formats[locale].time) : format, { locale: DateUtil.mapStringToLocale(locale) });
    } else {
      return dateFormat(date, DateUtil.mapMomentFormatToDataFns(format), { locale: DateUtil.mapStringToLocale(locale) });
    }
  }

  public static convertToDateNamed(dateString: string, format: string): number {
    return parse(dateString, DateUtil.mapMomentFormatToDataFns(format), new Date()).getTime();
  }

  public static convertToDateNumeric(value: string, formats: DateTimeFormats, locale: string): Date {
    return parse(value, DateUtil.getFormat(locale, formats, false), new Date(), { locale: DateUtil.mapStringToLocale(locale) });
  }

  public static isOverDue(dueDate: number): boolean {
    const now = new Date();

    return isAfter(now, dueDate);
  }

  public static isTodayOrLate(rangeStart: number, rangeEnd: number, threshold?: number): boolean {
    const useThreshold = threshold || 10; // default: in last 10%

    const dueDate = new Date(rangeEnd);
    dueDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (DateUtil.isToday(dueDate) || DateUtil.yesterdayOrTomorrow(dueDate, today)) {
      return true;
    }

    const end = new Date(rangeEnd);
    const now = new Date();

    if (isAfter(now, end)) {
      return true;
    }

    const start = new Date(rangeStart);

    const rangeDiff = Math.abs(differenceInCalendarDays(start, end));
    const nowDiff = Math.abs(differenceInCalendarDays(now, end));
    const percent = nowDiff / (rangeDiff / 100);

    return percent <= useThreshold;
  }

  public static isToday(dueDate: Date): boolean {
    const now = new Date();

    if (dueDate.getFullYear() === now.getFullYear() && dueDate.getMonth() === now.getMonth()) {
      return now.getDate() === dueDate.getDate();
    } else {
      return false;
    }
  }

  public static daysDifference(from: number, to: number): number {
    return Math.abs(differenceInCalendarDays(from, to));
  }

  public static mapMomentFormatToDataFns(format: string): string {
    switch (format) {
      case 'L': {
        return 'P';
      }
      case 'LL': {
        return 'PPP';
      }
      case 'LLL': {
        return 'PPPp';
      }
      case 'LT': {
        return 'p';
      }
      default: {
        return format;
      }
    }
  }

  public static mapStringToLocale(localeString: string): Locale {
    switch (localeString) {
      case 'de': {
        return de;
      }
      case 'fr': {
        return fr;
      }
      default: {
        return enUS;
      }
    }
  }

  public static parseISO(iso: string): number {
    return parseISO(iso).getTime();
  }

  public static toISO(...args: Parameters<typeof formatISO>): string {
    return formatISO(...args);
  }

  public static getFormat(locale: string, formats: DateTimeFormats = DateUtil.DEFAULT_FORMATS, withTime: boolean = false): string {
    const localeToUse = ['de', 'fr', 'en'].includes(locale) ? locale : 'en';

    return withTime ? `${formats[localeToUse].date} ${formats[localeToUse].time}` : formats[localeToUse].date;
  }

  public static addTimeToGivenFormat(format: string, locale: string, timeFormat: string): string {
    return `${format} ${timeFormat}`;
  }

  public static yesterdayOrTomorrow(dueDate: Date, today: Date): boolean {
    const startOfYesterday = new Date(today).setHours(0, 0, 0, 0) - 1000 * 3600 * 24;
    const endOfTomorrow = new Date(today).setHours(23, 59, 59, 59) + 1000 * 3600 * 24;

    return dueDate.getTime() >= startOfYesterday && dueDate.getTime() <= endOfTomorrow;
  }
}
