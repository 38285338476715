import { CelumMap } from '../../../core/common/celum-map';
import { EntityType } from '../../../core/model/entity-type';
import { OperationDefinition } from './operation-definition';

export class OperationsMap extends CelumMap<EntityType, OperationDefinition[]> {

  public translateKey(key: EntityType): string {
    return key.id;
  }
}
