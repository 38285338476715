import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, UntypedFormControl } from '@angular/forms';

export function required({ value }: AbstractControl): any {
  // FormControl can contain not only string. So should be additional check
  // Because trim() isn't allowed for Objects or Arrays
  const trimmedValue = typeof value === 'string' ? value.trim() : value;

  // tslint:disable-next-line:triple-equals
  const valueIsExisting = trimmedValue != null && trimmedValue != '';

  return valueIsExisting ? null : {
    required: {
      valid: false,
      messageKey: 'VALIDATION.REQUIRED',
      params: {}
    }
  };
}

/**
 * Replaces built-in RequiredValidator
 */
@Directive({
             selector: '[required][ngModel]',
             providers: [
               {
                 provide: NG_VALIDATORS,
                 useExisting: RequiredValidator,
                 multi: true
               }
             ]
           })
export class RequiredValidator {

  @Input() public required: boolean;

  // https://blog.thoughtram.io/angular/2016/03/14/custom-validators-in-angular-2.html
  public validate(c: UntypedFormControl): any {
    return this.required ? required(c) : {};
  }
}
