import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'thousandSuffix'
})
export class ThousandSuffixPipe implements PipeTransform {
  public transform(input: number, decimals: number = 1): any {
    // tslint:disable-next-line:triple-equals
    if (isNaN(input) || input == null) {
      return null;
    }

    const sanitizedInput = Math.abs(input);
    if (sanitizedInput < 1000) {
      return sanitizedInput;
    }

    // tslint:disable-next-line:triple-equals
    const sanitizedDecimals = decimals == null ? 1 : decimals;
    const suffixes = ['k', 'm', 'G', 'T', 'P', 'E'];
    const exponent = Math.floor(Math.log(sanitizedInput) / Math.log(1000));

    const [integer, decimal] = (sanitizedInput / Math.pow(1000, exponent)).toString().split('.');
    const decimalStringPart = decimal && sanitizedDecimals > 0 ? '.' + decimal.slice(0, sanitizedDecimals) : '';
    return integer + decimalStringPart + suffixes[exponent - 1];
  }
}
