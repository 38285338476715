import { defer, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import { CelumPropertiesProvider } from '../../core';
import { CelumSubject } from '../async/celum-subject';
import { Command } from '../model/command';
import { NamedQuery, PageableNamedQuery } from '../model/named-query';
import { NewObjectGraphResult } from '../result/new-object-graph-result';
import { NewPagedObjectGraphResult } from '../result/new-paged-object-graph-result';
import { HttpProxy } from './http-proxy';

const QUERY_WINDOW_TIME = 10000;

export interface HttpProxyInterceptor {
  intercept(url: string, data: any, method: string, headers: { [key: string]: string }): void;
}

export class HttpProxyInvoker {

  protected httpProxy: HttpProxy;

  constructor(interceptors: HttpProxyInterceptor[]) {
    this.httpProxy = new HttpProxy(interceptors);
  }

  /**
   * Relies on image online check! If this is not configured -> do not rely on that!
   */
  public isConnected(): Observable<boolean> {
    return this.httpProxy.isConnected();
  }

  public executeCommand(baseAddress: string, command: Command, expectResult: boolean = true): Observable<CelumSubject> {
    HttpProxyInvoker.logCommandQueryName(command.getCommandName());
    return this.replayProxyStream(this.httpProxy.executeCommand(baseAddress, command, expectResult), command.getCommandName());
  }

  public executeCommandWithGraphResult(baseAddress: string, command: Command): Observable<NewObjectGraphResult> {
    HttpProxyInvoker.logCommandQueryName(command.getCommandName());
    return this.replayProxyStream(this.httpProxy.executeCommandWithGraphResult(baseAddress, command), command.getCommandName());
  }

  /**
   * Execute a named query on the given base address.
   * <b>Note</b>: In case of connection loss a "failed message" is send via the results observable.
   *
   * @param baseAddress    the endpoint of the event bus to use for this query
   * @param query     the named query to execute
   * @returns an observable (single!) containing the result object created by the passed <code>createResult</code> function
   */
  public queryNamed(baseAddress: string, query: NamedQuery): Observable<CelumSubject> {
    const queryName = query.getQueryName();

    HttpProxyInvoker.logCommandQueryName(queryName);

    const deferredExecute$ = this.httpProxy.query(baseAddress, query, query.getQueryName());
    return this.replayProxyStream(deferredExecute$, queryName);
  }

  /**
   * Execute a named query and return the results via {@link NewObjectGraphResult}.
   * <b>Note</b>: In case of connection loss the {@link NewObjectGraphResult} takes care of executing the query again if necessary.
   * <b>Note</b>: If the header or the first message (usually ACK from server) does not arrive in <code>firstMessageTimeout</code> ms, the query is executed
   * again automatically.
   *
   * @param baseAddress    the endpoint of the event bus to use for this query
   * @param query               the named query to execute
   * @param autoUpdate    whether the query should be executed again in intervals
   * @param name           name of the query
   * @param customUpdateInterval   specify a custom interval for the "update" of the query (full re-execution)
   * @returns an observable which emits once the {@link NewObjectGraphResult}
   */
  public queryNamedAsGraph(baseAddress: string, query: NamedQuery, autoUpdate?: boolean, name?: string,
                           customUpdateInterval?: number): Observable<NewObjectGraphResult> {
    // tslint:disable-next-line:no-parameter-reassignment
    name = name || query.getQueryName();

    HttpProxyInvoker.logCommandQueryName(name);

    const deferredExecute$ = this.httpProxy.queryAsGraph(baseAddress, query, autoUpdate, name, customUpdateInterval);

    return this.replayProxyStream(deferredExecute$, name);
  }

  /**
   * Execute a named query and return the results via {@link NewPagedObjectGraphResult}.
   * <b>Note</b>: In case of connection loss the {@link NewPagedObjectGraphResult} takes care of executing the query again if necessary.
   * <b>Note</b>: If the header or the first message (usually ACK from server) does not arrive in <code>firstMessageTimeout</code> ms, the query is executed
   * again automatically.
   *
   * @param baseAddress      the endpoint of the event bus to use for this query
   * @param queryBuilder    method which returns the actual query to execute (based on the
   *   current page)
   * @param limit            the size for a page to load
   * @param startWithOffset  the offset for the first query (usually 0)
   * @param autoUpdate      whether the query should be executed again in intervals
   * @param name             name of the query
   * @param customUpdateInterval   specify a custom interval for the "update" of the query (full re-execution)
   * @returns an observable which emits once the {@link NewPagedObjectGraphResult}
   */
  public queryNamedAsPagedGraph(baseAddress: string, queryBuilder: (offset: number, limit: number) => Promise<PageableNamedQuery>, limit: number,
                                startWithOffset: number = 0, autoUpdate?: boolean, name?: string,
                                customUpdateInterval?: number): Observable<NewPagedObjectGraphResult> {
    HttpProxyInvoker.logCommandQueryName(name);

    const deferredExecute$ = defer(
      () => this.httpProxy.queryAsPagedGraph(baseAddress, queryBuilder, limit, startWithOffset, autoUpdate, name, customUpdateInterval));

    return this.replayProxyStream(deferredExecute$, name);
  }

  /**
   * Method which takes a result stream and replays the result for a certain amount of time. If a subscriber is too late meaning the result was already
   * discarded, a warning message is logged to the console.
   */
  private replayProxyStream<T>(stream$: Observable<T>, name: string): Observable<T> {
    let firstSubscriberTime: number;
    let firstResultTime: number;
    let performanceLogged = false;

    return defer(() => {
      firstSubscriberTime = firstSubscriberTime ?? Date.now();
      return stream$;
    }).pipe(
      shareReplay(1, QUERY_WINDOW_TIME),
      tap({
            next: () => {
              firstResultTime = firstResultTime ?? Date.now();
              if (!performanceLogged) {
                HttpProxyInvoker.logPerformance(firstSubscriberTime, name);
                performanceLogged = true;
              }
            },
            complete: () => {
              const timeSinceFirstSubscription = Date.now() - firstSubscriberTime;
              const timeSinceFirstResult = Date.now() - firstResultTime;
              if (timeSinceFirstResult > QUERY_WINDOW_TIME) {
                console.warn(
                  `HttpProxyInvoker: A late subscriber for "${name}" did not get a value! Please make sure that subsequent subscriptions to the result happen within the defined query window time (${QUERY_WINDOW_TIME}ms). In this case, the subscription was created ${timeSinceFirstSubscription}ms after the initial subscription and ${timeSinceFirstResult}ms after the initial result.`);
              }
            }
          }),
    );
  }

  private static logPerformance(start: number, name: string): void {
    if (CelumPropertiesProvider.properties.logPerformanceMeasurements) {
      const end = Date.now();

      if (name) {
        console.log(`Performance: receiving result for query ${name} (from server) took ${(end - start)}ms`);
      } else {
        console.log(`Performance: receiving result from server took ${(end - start)}ms`);
      }
    }
  }

  private static logCommandQueryName(name: string): void {
    if (CelumPropertiesProvider.properties.logSendMessages) {
      console.log('Execute query/command', name);
    }
  }
}
