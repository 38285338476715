import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

import { EntityType, GraphEntity, GraphRelation, isSimpleArrayEqual } from '@celum/core';

import { EntitiesState, getEntitiesByIds, getEntityById } from './entities/entities-state';
import { RelationsListState, selectRelationListById, selectRelationsByIds } from './relations/relations-list-state';

export interface EntitiesRelationState {
  entities: EntitiesState;
  relationList: RelationsListState;
}

// @dynamic (tell ngc that none of the methods of this class will be used in a decorator)
export class RelationHelper {

  private static readonly NO_ENTITY_MESSAGE = 'RelationHelper: Entity does not exist! Check if given id is correct and entity is in store';

  // ==============================================================================================================================
  // =======================================================   PUBLIC API   =======================================================
  // ==============================================================================================================================

  /**
   * Return target entity of given entity to given relationType
   * @param store Store
   * @param sourceId id of source entity
   * @param relationType Relation which to follow
   * @returns Entity if found, undefined if not
   */
  public static singleTargetEntity<T extends GraphEntity>(store: Store<any>, sourceId: string, relationType: EntityType): Observable<T> {
    return this.getRelatedEntities<T>(store, sourceId, 'target', 'single', relationType);
  }

  /**
   * Return source entity of given entity to given relationType
   * @param store Store
   * @param targetId id of target entity
   * @param relationType Relation which to follow
   * @returns Entity if found, undefined if not
   */
  public static singleSourceEntity<S extends GraphEntity>(store: Store<any>, targetId: string, relationType: EntityType): Observable<S> {
    return this.getRelatedEntities<S>(store, targetId, 'source', 'single', relationType);
  }

  /**
   * Return target entities of given entity to given relationType
   * @param store Store
   * @param sourceId id of source entity
   * @param relationType Relation which to follow
   * @returns Array of entities if found, empty array if no entity was found
   */
  public static resolveTargetEntities<T extends GraphEntity>(store: Store<any>, sourceId: string, relationType: EntityType): Observable<T[]> {
    return this.getRelatedEntities<T>(store, sourceId, 'target', 'multi', relationType);
  }

  /**
   * Return source entities of given entity to given relationType
   * @param store Store
   * @param targetId id of target entity
   * @param relationType Relation which to follow
   * @returns Array of entities if found, empty array if no entity was found
   */
  public static resolveSourceEntities<S extends GraphEntity>(store: Store<any>, targetId: string, relationType: EntityType): Observable<S[]> {
    return this.getRelatedEntities<S>(store, targetId, 'source', 'multi', relationType);
  }

  /**
   * Return target relation of given entity to given relationType
   * @param store Store
   * @param sourceId id of source entity
   * @param relation Relation which to follow
   * @returns relation if found, undefined if not
   */
  public static singleTargetRelation<T extends GraphRelation>(store: Store<any>, sourceId: string, relation: EntityType): Observable<T> {
    return store.pipe(
      select(getEntityById(sourceId)),
      switchMap(entity => this.getRelations('target', store, entity, relation).pipe(
        map(relations => relations ? relations[0] as T : null),
        distinctUntilChanged()
      ))
    );
  }

  /**
   * Return source relation of given entity to given relationType
   * @param store Store
   * @param targetId id of target entity
   * @param relationType Relation which to follow
   * @returns relation if found, undefined if not
   */
  public static singleSourceRelation<T extends GraphRelation>(store: Store<any>, targetId: string, relationType: EntityType): Observable<T> {
    return store.pipe(
      select(getEntityById(targetId)),
      switchMap(entity => this.getRelations('source', store, entity, relationType).pipe(
        map(relations => relations ? relations[0] as T : null),
        distinctUntilChanged()
      ))
    );
  }

  /**
   * Return target relations entities of given entity to given relationType
   * @param store Store
   * @param sourceId id of source entity
   * @param relationType Relation which to follow
   * @returns Array of relations if found, empty array if no relation was found
   */
  public static resolveTargetRelations<T extends GraphRelation>(store: Store<any>, sourceId: string, relationType: EntityType): Observable<T[]> {
    return store.pipe(
      select(getEntityById(sourceId)),
      switchMap(entity => this.getRelations<T>('target', store, entity, relationType)),
      distinctUntilChanged(isSimpleArrayEqual)
    );
  }

  /**
   * Return source relations of given entity to given relationType
   * @param store Store
   * @param targetId id of target entity
   * @param relationType Relation which to follow
   * @returns Array of relations if found, empty array if no relation was found
   */
  public static resolveSourceRelations<R extends GraphRelation>(store: Store<any>, targetId: string, relationType: EntityType): Observable<R[]> {
    return store.pipe(
      select(getEntityById(targetId)),
      switchMap(entity => this.getRelations<R>('source', store, entity, relationType)),
      distinctUntilChanged(isSimpleArrayEqual)
    );
  }

  /**
   * Same behaviour as {@link getRelatedEntities} but it only evaluates once for the given state snapshot.
   * If you want to be notified on changes, use {@link singleSourceEntity, singleTargetEntity, resolveSourceEntities, resolveTargetEntities}
   */
  public static getRelatedEntitiesOnce<T extends GraphEntity>(state: EntitiesRelationState, id: string, idsFor: 'source' | 'target', quantity: 'single',
                                                              relationType: EntityType): T;
  public static getRelatedEntitiesOnce<T extends GraphEntity>(state: EntitiesRelationState, id: string, idsFor: 'source' | 'target', quantity: 'multi',
                                                              relationType: EntityType): T[];
  public static getRelatedEntitiesOnce<T extends GraphEntity>(state: EntitiesRelationState, id: string, idsFor: 'source' | 'target',
                                                              quantity: 'single' | 'multi', relationType: EntityType): T | T[] {
    const entitiesById = state.entities.entitiesById;
    const entity = entitiesById[id];
    const targetIds = this.getIdsOnce(state.relationList, idsFor, entity, relationType);

    if (quantity === 'single') {
      return entitiesById[targetIds[0]] as T;
    } else {
      return targetIds.map(targetId => entitiesById[targetId]) as T[];
    }
  }

  /**
   * Same behaviour as {@link getIds} but it only evaluates once for the given state snapshot
   */
  private static getIdsOnce(state: RelationsListState, idsFor: 'target' | 'source', entity: GraphEntity, relationType: EntityType): string[] {
    if (!entity) {
      console.warn(RelationHelper.NO_ENTITY_MESSAGE);
      return [];
    }

    const relationListId = entity.relations.get(relationType.id);
    if (!relationListId) {
      return [];
    }

    const relationList = state.relationLists[relationListId];
    const relationIds = relationList && relationList.relationIds || [];
    return (relationIds || []).map(id => state.relations[id])
                              .filter(relation => !!relation)
                              .filter(relation => (idsFor === 'target' ? relation.fromId : relation.toId) === entity.id)
                              .map(relation => idsFor === 'target' ? relation.toId : relation.fromId);
  }

  // ==============================================================================================================================
  // ======================================================= HELPER METHODS =======================================================
  // ==============================================================================================================================

  /**
   * Get ids of all entities that are targets or source entities for the given relation on given entity.
   * Listens to changes on the affected relationList to gather if relations have changed.
   *
   * @param idsFor whether the target or source entities are wanted
   * @param store the store
   * @param entity source entity
   * @param relationType relation type which should be followed
   * @return array of target ids or empty array if no targets exist
   */
  private static getIds(idsFor: 'target' | 'source', store: Store<any>, entity: GraphEntity, relationType: EntityType): Observable<string[]> {
    if (!entity) {
      console.warn(this.NO_ENTITY_MESSAGE);
      return of([]);
    }

    const relationListId = entity.relations.get(relationType.id);
    if (!relationListId) {
      return of([]);
    }

    const relationList$ = store.pipe(select(selectRelationListById, { id: relationListId }));

    return relationList$.pipe(
      switchMap(relationList => {
        const relationIds = relationList && relationList.relationIds || [];
        return store.pipe(
          select(selectRelationsByIds(relationIds)),
          take(1) // because we are only interested in the target and not the relations itself
        );
      }),
      map(relations => relations.filter(relation => (idsFor === 'target' ? relation.fromId : relation.toId) === entity.id)
                                .map(relation => idsFor === 'target' ? relation.toId : relation.fromId)
      ),
      distinctUntilChanged(isSimpleArrayEqual)
    );
  }

  /**
   * Get relations that are targets or source entities for the given relation on given entity.
   * Listens to changes on the affected relationList to gather if relations have changed.
   *
   * @param relationsFor whether target or source entities are wanted
   * @param store the store
   * @param entity source entity
   * @param relationType relation type which should be followed
   * @return array of target relations or empty array if no targets exist
   */
  private static getRelations<R extends GraphRelation>(relationsFor: 'target' | 'source', store: Store<any>, entity: GraphEntity,
                                                       relationType: EntityType): Observable<R[]> {
    if (!entity) {
      console.warn(RelationHelper.NO_ENTITY_MESSAGE);
      return of([]);
    }

    const relationListId = entity.relations.get(relationType.id);
    if (!relationListId) {
      return of([]);
    }

    const relationList$ = store.pipe(select(selectRelationListById, { id: relationListId }));

    return relationList$.pipe(
      switchMap(relationList => {
        const relationIds = relationList && relationList.relationIds || [];
        return store.pipe(select(selectRelationsByIds<R>(relationIds)));
      }),
      map(relations => relations.filter(relation => (relationsFor === 'target' ? relation.fromId : relation.toId) === entity.id)),
      distinctUntilChanged(isSimpleArrayEqual)
    );
  }

  /**
   * Resolve related entities to the entity with given id
   * @param store Store
   * @param id of the entity of which related entities are wanted
   * @param idsFor whether to search for source or target entities
   * @param quantity whether to expect a single result or multiple results
   * @param relationType which should be followed
   */
  private static getRelatedEntities<T extends GraphEntity>(store: Store<any>, id: string, idsFor: 'source' | 'target', quantity: 'single',
                                                           relationType: EntityType): Observable<T>;
  private static getRelatedEntities<T extends GraphEntity>(store: Store<any>, id: string, idsFor: 'source' | 'target', quantity: 'multi',
                                                           relationType: EntityType): Observable<T[]>;
  private static getRelatedEntities<T extends GraphEntity>(store: Store<any>, id: string, idsFor: 'source' | 'target', quantity: 'single' | 'multi',
                                                           relationType: EntityType): Observable<T | T[]> {
    return store.pipe(
      select(getEntityById(id)),
      switchMap(entity => this.getIds(idsFor, store, entity, relationType)),
      distinctUntilChanged(isSimpleArrayEqual), // keep this check -> necessary because getIds will return a new array for each emit of the first select!
      switchMap(targetIds => {
        if (quantity === 'single') {
          return store.pipe(select(getEntityById<T>(targetIds[0])));
        }
        return store.pipe(select(getEntitiesByIds<T>(targetIds)));
      })
    );
  }

}
