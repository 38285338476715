import { HttpClient, HttpEventType, HttpProgressEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { FileUpload } from '../model/file-upload';
import { FileUploadConfig, UploadEngine } from '../model/upload-engine';

@Injectable({ providedIn: 'root' })
export class HttpUploadEngine implements UploadEngine {

  constructor(private httpClient: HttpClient) {
  }

  public prepareUpload(config: HttpUploadConfig, file: File): FileUpload {

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const httpRequestOptions = new HttpRequest('POST', config.uploadUrl, formData, {
      reportProgress: true
    });

    const cancel$ = new Subject<void>();

    const upload$ = this.httpClient.request(httpRequestOptions).pipe(
      filter(event => event.type === HttpEventType.UploadProgress),
      map(event => (event as HttpProgressEvent).loaded),
      takeUntil(cancel$)
    );

    return {
      start$: upload$,
      cancel: () => cancel$.next()
    };
  }

}

export interface HttpUploadConfig extends FileUploadConfig {
  uploadUrl: string;
}
