export function formatNumber(input: number | string, locale: string): string {
  const numberValue = typeof input === 'string' ? parseFloat(input) : input;
  if (Number.isNaN(numberValue)) {
    return '';
  }
  const [prefix, power] = numberValue.toString(10).split('e');
  const decimals = numberValue.toString(10).split('.')[1]?.split('e')[0];
  return parseFloat(prefix).toLocaleString(locale === 'en' ? 'en-GB' : locale, {
    minimumFractionDigits: decimals?.length ?? 0
  }) + (power ? 'e' + power : '');
}
