import { Injectable } from '@angular/core';

import { DataUtil } from '@celum/core';

@Injectable()
export class LoginRedirectService {
  private readonly LOGIN_REDIRECT_URI: string = 'celum.auth.login.redirect';

  public storeLoginRedirectUri(redirectUri: string): void {
    const finalRedirectUri = this.cleanRedirectUri(redirectUri);
    if (finalRedirectUri) {
      sessionStorage.setItem(this.LOGIN_REDIRECT_URI, finalRedirectUri);
    }
  }

  public getLoginRedirectUri(): string {
    return sessionStorage.getItem(this.LOGIN_REDIRECT_URI);
  }

  public clearLoginRedirectUri(): void {
    sessionStorage.removeItem(this.LOGIN_REDIRECT_URI);
  }

  public cleanRedirectUri(redirectUri: string): string {
    if (DataUtil.isEmpty(redirectUri)) {
      return undefined;
    }

    // Sometimes for unknown reasons after a single sign-out the app would be redirected to /logout after login, so we have to manually prevent storing that value
    if (redirectUri === '/logout') {
      return undefined;
    }

    // Finally, make sure we don't store the "/tenant/[tenantId]" as part of the url, because that is never part of the routing and would therefore cause a redirect
    // to the home page after login
    return redirectUri.replace(/\/tenant\/[^\/]+/, '');
  }
}
