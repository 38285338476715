export class ValidatorUtil {
  public static isEmailValid(email: string): boolean {
    if (!email) {
      return false;
    }

    // Using email standard RFC 5322, implementation provided by http://emailregex.com/
    // Regex and test cases are matched with Backend EmailAddressValidator(Test).java
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }
}
