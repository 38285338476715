import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CelumPropertiesProvider } from '../../core/configuration/celum-properties-provider';
import { EntityType } from '../../core/model/entity-type';
import { GraphEntity, GraphRelation } from '../../core/model/graph-entity';
import { TranslatorRegistry } from '../../core/translation/translator-registry';
import { AdditionalData } from '../model/additional-data';
import { ObjectGraphException } from '../model/error';
import { Message } from '../model/message';
import { MetaInfo } from '../model/meta-info';

export class GraphResultProcessor {

  private metaInfo: MetaInfo;

  public process(messages$: Observable<Message[]>): Observable<any[]> {
    return messages$.pipe(
      filter(messages => !!messages),
      map(messages => messages.map(msg => this.processMessage(msg)).filter(processed => !!processed)),
      filter(messages => messages && messages.length > 0)
    );
  }

  protected processUnknownMessage(msg: Message, msgData: any): any {
    const errorMessage = 'ObjectGraphResult: Telegram MessageData cannot be processed: ' + JSON.stringify(msgData);
    console.error(errorMessage);
    throw new ObjectGraphException('ERRORS.OBJECT_GRAPH_RESULT.ERROR_LOADING');
  }

  private processMessage(msg: Message): any {
    // tslint:disable-next-line:triple-equals
    if (msg == null) {
      return null;
    }

    const msgData = msg.data;
    if (CelumPropertiesProvider.properties.logCommunication) {
      console.debug('ObjectGraphResult: Telegram MessageData to process: ' + JSON.stringify(msg));
    }

    if (isHeader(msg)) {
      this.metaInfo = new MetaInfo(msgData);
      return this.metaInfo;
    } else if (!this.metaInfo) {
      console.error('Got no header yet, cannot process message!', msg);
      throw new Error('Invalid order of events! MetaInfo has not arrived yet!');
    } else if (isEntity(msg)) {
      return this.getEntityDto(msgData);
    } else if (isRelation(msg)) {
      return this.getRelationDto(msgData);
    } else if (isDetailCount(msg)) {
      return this.processDetailCountMessage(msgData);
    } else if (isAdditionalData(msg)) {
      return new AdditionalData(msgData.data);
    } else if (this.metaInfo) {
      return this.processUnknownMessage(msg, msgData);
    }

    return null;
  }

  private getEntityDto(msgData: any): GraphEntity {
    if (CelumPropertiesProvider.properties.logCommunication) {
      console.debug('ObjectGraphResult: processEntity msgData: ' + JSON.stringify(msgData, null, '  '));
    }

    const translator = TranslatorRegistry.getTranslator('graph:' + msgData.typeKey);

    // tslint:disable-next-line:triple-equals
    if (translator == null) {
      console.warn('ObjectGraphResult: No translator found for data object ' + JSON.stringify(msgData, null, '  '));
      return null;
    } else {
      const entityDto = translator.translateToEntity(msgData) as GraphEntity;

      // set dependency information
      this.metaInfo.getKnownRelations().forEach(relationType => {
        if (entityDto.knownRelationTypes.has(relationType)) {
          entityDto.relations.set(relationType, `${entityDto.id}_${relationType}`);
        }
      });

      const knownDetailCountIdentifier: Set<string> = new Set<string>();

      if (this.metaInfo.isAnyCountIdentifierRequestedForEntity(entityDto.typeKey)) {
        this.extractKnownDetailCountInformation(knownDetailCountIdentifier, entityDto.entityType);
      }

      entityDto.entityType.inheritsFrom.forEach(superType => {
        if (this.metaInfo.isAnyCountIdentifierRequestedForEntity(superType.id)) {
          this.extractKnownDetailCountInformation(knownDetailCountIdentifier, superType);
        }
      });

      entityDto.knownDetailCountIdentifier = knownDetailCountIdentifier;

      return entityDto;
    }
  }

  private extractKnownDetailCountInformation(knownDetailCountIdentifier: Set<string>, entityType: EntityType): void {
    this.metaInfo.getKnownDetailCountIdentifier(entityType.id).forEach(entityTypeIdentifier => {
      knownDetailCountIdentifier.add(entityTypeIdentifier);
    });
  }

  private getRelationDto(msgData: any): GraphRelation {
    if (CelumPropertiesProvider.properties.logCommunication) {
      console.debug('ObjectGraphResult: processRelation msgData: ' + JSON.stringify(msgData, null, '  '));
    }

    const translator = TranslatorRegistry.getTranslator('graph:' + msgData.typeKey);

    // tslint:disable-next-line:triple-equals
    if (translator == null) {
      console.warn('ObjectGraphResult: No translator found for data object ' + JSON.stringify(msgData, null, '  '));
      return null;
    } else {
      const relationDto = translator.translateToEntity(msgData) as GraphRelation;

      // tslint:disable-next-line:triple-equals
      if (relationDto == null) {
        console.warn('ObjectGraphResult: no result');
      }

      return relationDto;
    }
  }

  private processDetailCountMessage(msgData: any): DetailCountInfo {
    if (CelumPropertiesProvider.properties.logCommunication) {
      console.debug('ObjectGraphResult: process detail count msgData: ' + JSON.stringify(msgData, null, '  '));
    }

    const entityId: string = msgData.entityId;
    const countIdentifier: string = msgData.identifier;
    const detailCount: number = msgData.count;

    return {
      id: `${entityId}_${countIdentifier}`,
      count: detailCount
    };
  }
}

export function isHeader(msg: Message): boolean {
  return msg.data ? msg.data.type === 'MetaInfo' : false;
}

export function isEntity(msg: Message): boolean {
  return msg.data ? msg.data.type === 'Entity' : false;
}

export function isRelation(msg: Message): boolean {
  return msg.data ? msg.data.type === 'Relation' : false;
}

export function isDetailCount(msg: Message): boolean {
  return msg.data ? msg.data.type === 'DetailCount' : false;
}

export function isAdditionalData(msg: Message): boolean {
  return msg.data ? msg.data.type === 'AdditionalData' : false;
}

export interface DetailCountInfo {
  id: string;
  count: number;
}
