// we cant use CelumPropertiesProvider as this would introduce a dependency on @celum/core
declare let Celum: { properties: { appProperties: { portals: CelumPortalsProperties } } };

export interface CelumPortalsProperties {
  url: string;
  apiUrl: string;
  binariesUrl: string;
}

export class PortalsProperties {
  static get properties(): CelumPortalsProperties {
    const testingFallback = { url: 'test', apiUrl: `test-api`, binariesUrl: 'test-binaries' };

    try {
      return Celum?.properties.appProperties?.portals ?? testingFallback;
    } catch (err) {
      return testingFallback;
    }
  }
}
