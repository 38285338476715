import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { GeneralSettings, PortalConfiguration, WidgetConfiguration } from '@celum/portals/domain';

import { ConfigurationService, WidgetConfigInfo } from '../model/configuration.service';
import { ConfigurationResolver } from './configuration-resolver';

@Injectable()
export class ReadonlyConfigurationService implements ConfigurationService {
  public generalSettings$ = new BehaviorSubject<GeneralSettings>(null);
  public portalConfiguration$ = new BehaviorSubject<PortalConfiguration>(null);
  public widgets$ = new BehaviorSubject<WidgetConfiguration<any>[]>(null);
  public portalId$ = new BehaviorSubject<string>(null);

  constructor(configurationResolver: ConfigurationResolver) {
    const config = configurationResolver.configuration$.getValue();

    this.portalId$.next(config.id);
    this.portalConfiguration$.next(config);
    this.generalSettings$.next(config.configuration.generalSettings);
    this.widgets$.next(config.configuration.pages[0].widgets);
  }

  public showWidgetConfiguration(configInfo: WidgetConfigInfo): void {
    console.warn('You are not in edit mode! "showWidgetConfiguration" does nothing in edit mode!');
  }

  public getPortalId(): string {
    return this.portalId$.value;
  }
}
