import { mergeDeep as coreMergeDeep } from '@celum/core';

/** Ignore the new value and just keep the old one */
export function ignore<T>(prev: T, next: T): T {
  return prev;
}

/** Ignore the old value and just keep the new one */
export function overwrite<T>(prev: T, next: T): T {
  return next;
}

/** Ignore the old value and just keep the new one, except if the new value is null or undefined, then stay at the old value */
export function overwriteIfSet<T>(prev: T, next: T): T {
  // tslint:disable-next-line:triple-equals
  return next == null ? prev : next;
}

/** Merge the new entity onto the old one in a recursive manner */
export function mergeDeep<T extends object>(prev: T, next: T): T {
  return coreMergeDeep(prev, next);
}

/** Merge the new entity onto the old one in a non-recursive manner */
export function mergeShallow<T extends object>(prev: T, next: T): T {
  if (!prev || !next) {
    return next;
  }

  return { ...prev, ...next };
}
