import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

type RenderOnHoverTarget = ElementRef | { element?: ElementRef, _element?: ElementRef, el?: ElementRef, elementRef?: ElementRef, _elementRef?: ElementRef };

/**
 * This structural directive renders its content only if another target element is hovered.
 * It works for both mouse (mouseenter) and touch (touchstart) users.
 *
 * Usage:
 *
 * <div #trigger></div>
 * <div *renderOnHover="trigger">I am getting rendered if the trigger is hovered</div>
 *
 */
@Directive({
             selector: '[renderOnHover]'
           })
export class RenderOnHoverDirective {

  private subscription: Subscription;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input()
  public set renderOnHover(target: RenderOnHoverTarget) {
    if (!target) {
      return;
    }

    const elementRef = RenderOnHoverDirective.findElementRef(target);
    if (!elementRef) {
      console.error('RenderOnHoverDirective: passed target is not of type ElementRef or has no property that is!', target);
      return;
    }

    this.subscription?.unsubscribe();
    const mouseEnter$ = fromEvent(elementRef.nativeElement, 'mouseenter');
    const touchStart$ = fromEvent(elementRef.nativeElement, 'touchstart');
    this.subscription = merge(mouseEnter$, touchStart$).pipe(take(1)).subscribe(() => this.viewContainer.createEmbeddedView(this.templateRef));
  }

  /**
   * It is often convenient to just pass a template variable which might not always be an ElementRef, so as a convenience feature we also support objects where
   * we find an elementRef as a property for some names. E.g. material components like mat-button will also work like this
   * @param element which should be checked if its really an elementRef
   */
  private static findElementRef(element: RenderOnHoverTarget): ElementRef {
    if (element instanceof ElementRef) {
      return element;
    }

    return element.element instanceof ElementRef ? element.element :
           element._element instanceof ElementRef ? element._element :
           element.elementRef instanceof ElementRef ? element.elementRef :
           element._elementRef instanceof ElementRef ? element._elementRef :
           element.el instanceof ElementRef ? element.el : null;
  }

}
