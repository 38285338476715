import { EMPTY, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { CelumSubject } from '../async/celum-subject';
import { ConnectionLostHandler } from '../communication/connection-lost-handler';
import { Message } from '../model/message';
import { NewObjectGraphResult } from './new-object-graph-result';

export type PagedUpdateFn = (offset: number, limit: number) => Promise<CelumSubject>;
export type DefaultUpdateFn = () => Promise<CelumSubject>;
export type UpdateFn = PagedUpdateFn | DefaultUpdateFn;

export class ObjectGraphResultHelper {

  private connectionLostHandler: ConnectionLostHandler;
  private destroyed = false;

  constructor(private celumSubject: CelumSubject, private name: string, private autoUpdate: boolean, private updateQuery: UpdateFn) {
  }

  public init(graphResult: NewObjectGraphResult): void {
    this.connectionLostHandler = this.createConnectionLostHandler(this.celumSubject, graphResult, this.autoUpdate, this.name, () => {
      if (!this.destroyed) {
        graphResult.forceUpdate()
                   .catch(err => console.error(`ObjectGraphResultHelper: Error updating query "${this.name}" after connection restored`, err));
      }
    });
  }

  public executeUpdateQuery(offset?: number, limit?: number): Promise<Observable<Message[]>> {
    return new Promise<Observable<Message[]>>((resolve, reject) => {
      // tslint:disable-next-line:triple-equals
      const params = offset != null && limit ? [offset, limit] : [];

      console.debug(`ObjectGraphResultHelper: Execute update query "${this.name}"...`, params);

      this.updateQuery.apply(this.updateQuery, params).then((newSubject: CelumSubject) => {
        this.celumSubject.destroy();
        this.celumSubject = newSubject;

        this.connectionLostHandler.updateSubject(newSubject);
        resolve(this.mapResults(this.celumSubject));
      }).catch((err: any) => {
        console.error(`ObjectGraphResultHelper: Error executing update query "${this.name}"!`, err);
        reject('ObjectGraphResultHelper: Error executing update query!');
      });
    });
  }

  public mapResults(celumSubject?: CelumSubject): Observable<Message[]> {
    return (celumSubject ? celumSubject.getSubject() : this.celumSubject.getSubject())
      .pipe(switchMap(messages => {
        if (messages.some(message => message.failed)) {
          return EMPTY; // no need to log error message again, this is done in celumSubject already!
        }

        return of(messages);
      }));
  }

  public destroy(): void {
    this.destroyed = true;
    this.celumSubject.destroy();
    this.connectionLostHandler.destroy();
  }

  protected createConnectionLostHandler(celumSubject: CelumSubject, graphResult: NewObjectGraphResult, autoUpdate: boolean, name: string,
                                        updateCallback: () => void): ConnectionLostHandler {
    return new ConnectionLostHandler(celumSubject, autoUpdate, graphResult, name, updateCallback);
  }
}
