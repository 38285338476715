import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

@Pipe({
        name: 'observable',
        pure: true
      })
export class ObservablePipe implements PipeTransform {

  public transform(obj: any): Observable<any> {
    return ObservablePipe.isObservable(obj) ? obj : observableOf(obj);
  }

  private static isObservable(obj: any): obj is Observable<any> {
    // if the object has a "subscribe" method, we can assume it's an observable
    return obj && (obj as Observable<any>).subscribe !== undefined;
  }
}
