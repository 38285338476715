export class MetaInfo {

  private entities: Set<string> = new Set<string>();
  private relations: Set<string> = new Set<string>();
  // key => typeKey , value => set of countIdentifiers
  private detailCountIdentifiers: Map<string, Set<string>> = new Map<string, Set<string>>();

  // true if the telegram will contain some pagination information for the specific scope; 'rootScope' is the paging for the root query
  private pagingInformation: Map<string, boolean> = new Map<string, boolean>();

  constructor(rawMetaInfo: any) {
    rawMetaInfo.entities.forEach((entity: string) => {
      this.entities.add(entity);
    });

    rawMetaInfo.relations.forEach((relation: string) => {
      this.relations.add(relation);
    });

    this.extractPaginationScopeInformation(rawMetaInfo);
    this.extractDetailCountMap(rawMetaInfo);
  }

  public isEntityRequested(typeKey: string): boolean {
    return this.entities.has(typeKey);
  }

  public isRelationRequested(typeKey: string): boolean {
    return this.relations.has(typeKey);
  }

  public isCountIdentifierRequested(typeKey: string, countIdentifier: string): boolean {
    if (this.detailCountIdentifiers.has(typeKey)) {
      return this.detailCountIdentifiers.get(typeKey).has(countIdentifier);
    } else {
      return false;
    }
  }

  public getKnownDetailCountIdentifier(typeKey: string): Set<string> {
    return this.detailCountIdentifiers.get(typeKey);
  }

  public isAnyCountIdentifierRequestedForEntity(typeKey: string): boolean {
    // tslint:disable-next-line:triple-equals
    return this.detailCountIdentifiers.get(typeKey) != null;
  }

  public getKnownRelations(): Set<string> {
    return this.relations;
  }

  public getEntityTypes(): Set<string> {
    return this.entities;
  }

  public isPagingInformationAvailable(scope: string): boolean {
    return this.pagingInformation.has(scope);
  }

  private extractPaginationScopeInformation(rawMetaInfo: any): void {
    // tslint:disable-next-line:triple-equals
    if (rawMetaInfo.paginationInformation != null) {
      const mapKeys = Object.keys(rawMetaInfo.paginationInformation);
      mapKeys.forEach((scopeId: string) => {
        this.pagingInformation.set(scopeId, rawMetaInfo.paginationInformation[scopeId]);
      });
    }
  }

  private extractDetailCountMap(rawMetaInfo: any): void {
    // tslint:disable-next-line:triple-equals
    if (rawMetaInfo.detailCounts != null) {
      const mapKeys = Object.keys(rawMetaInfo.detailCounts);

      mapKeys.forEach((typeKey: string) => {
        let countIdentifiers: Set<string>;

        if (!this.detailCountIdentifiers.has(typeKey)) {
          countIdentifiers = new Set<string>();
          this.detailCountIdentifiers.set(typeKey, countIdentifiers);
        } else {
          countIdentifiers = this.detailCountIdentifiers.get(typeKey);
        }

        const detailCountMap = rawMetaInfo.detailCounts[typeKey][1];

        detailCountMap.forEach((identifier: string) => {
          countIdentifiers.add(identifier);
        });
      });
    }
  }

}
