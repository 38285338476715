import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { LogLevel } from '@azure/msal-common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import {
  ApiConfiguration,
  AuthInterceptorConfig,
  AUTH_SERVICE_CONFIG,
  CelumAuthModule,
  ServiceTokenInterceptorConfig,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  TenantService
} from '@celum/authentication';
import { CelumPropertiesProvider } from '@celum/core';
import { CustomTranslateLoader } from '@celum/ng2base';
import { PortalsProperties } from '@celum/portals/domain';
import { prepareApplicationInsights, provideApplicationInsights } from '@celum/shared/domain';

import { AppComponent } from './app/app.component';
import { ROUTES } from './app/app.routes';
import { PortalsAuthGuard } from './app/portals-auth.guard';
import { PortalsAuthInterceptor } from './app/portals-auth.interceptor';

export function translationFactory(http: HttpClient): CustomTranslateLoader {
  return new CustomTranslateLoader(http);
}

prepareApplicationInsights()
  .then(() => {
    return bootstrapApplication(AppComponent, {
      providers: [
        // Important: HttpClientModule needs to be imported in order for interceptors to work
        importProvidersFrom(BrowserAnimationsModule, HttpClientModule),
        // translation stuff is needed here to set up translations handling, actual translation setup is done in the portal component
        provideHttpClient(),
        provideRouter(ROUTES),
        importProvidersFrom(
          TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: translationFactory,
              deps: [HttpClient]
            }
          })
        ),
        importProvidersFrom(
          CelumAuthModule.forRoot({
            logLevel: LogLevel.Error
          })
        ),
        {
          provide: HTTP_INTERCEPTORS,
          useClass: PortalsAuthInterceptor,
          multi: true
        },
        {
          provide: SERVICE_TOKEN_INTERCEPTOR_CONFIG,
          useFactory: serviceTokenInterceptorFactory,
          deps: [TenantService]
        },
        {
          provide: AUTH_SERVICE_CONFIG,
          useValue: { loadSaccUserAfterSignIn: false }
        },
        PortalsAuthGuard,
        provideApplicationInsights()
      ]
    });
  })
  .catch(err => console.error(err));

function serviceTokenInterceptorFactory(tenantService: TenantService): ServiceTokenInterceptorConfig<AuthInterceptorConfig> {
  const getConfiguration = () => {
    const tenantIdForPortal = tenantService.getCurrentTenantId();

    const portalsAppServiceTokenRequest = {
      clientId: 'portals-app',
      orgId: tenantIdForPortal
    };

    const apiConfigurations: ApiConfiguration[] = [
      {
        apiUrls: [
          CelumPropertiesProvider.properties.appProperties.experience.apiUrl,
          PortalsProperties.properties.apiUrl,
          PortalsProperties.properties.binariesUrl
        ],
        serviceTokenRequestDto: portalsAppServiceTokenRequest,
        tenantIdHeader: tenantIdForPortal
      }
    ];

    return {
      apiConfigurations,
      passThrough403Error: true
    };
  };

  return {
    getInterceptorConfiguration: () => getConfiguration()
  };
}
