import { ValidatorFn } from '@angular/forms';

import { dateInFuture } from './date-in-future';
import { isDate } from './date-validator';
import { doubleValidator } from './double-validator';
import { validEmail } from './email-validator';
import { maxLength } from './max-length-validator';
import { minLength } from './min-length-validator';
import { notEmpty } from './not-empty-validator';
import { notIn } from './not-in-validator';
import { pureNumber } from './pure-number-validator';
import { rangeNumber } from './range-number-validator';
import { required } from './required-validator';
import { validCharacters } from './valid-characters-validator';

// compare to
// https://github.com/angular/angular/blob/master/modules/%40angular/forms/src/validators.ts

export class CelumValidators {
  public static required: ValidatorFn = required;
  public static notEmpty: ValidatorFn = notEmpty;
  public static double: ValidatorFn = doubleValidator;
  public static isDate: ValidatorFn = isDate;
  public static validEmail: ValidatorFn = validEmail;
  public static minLength = minLength;
  public static maxLength = maxLength;
  public static notIn = notIn;
  public static validCharacters = validCharacters;
  public static dateInFuture: ValidatorFn = dateInFuture;
  public static pureNumber: ValidatorFn = pureNumber;
  public static rangeNumber: ValidatorFn = rangeNumber;
}
