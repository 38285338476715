import { Observable } from 'rxjs';

import { ConnectionState } from '../communication/connection-checker';
import { Message } from '../model/message';
import { CelumReplaySubject } from './celum-replay-subject';
import { CelumSubject } from './celum-subject';

export class SimpleCelumSubject implements CelumSubject {

  constructor(private subject: CelumReplaySubject<Message[]>, private connectionState: Observable<ConnectionState>) {

  }

  public destroy(): void {
    if (this.subject && (this.subject.hasStopped && !this.subject.hasStopped())) {
      try {
        // just make sure that this does not crash the application...
        this.subject.complete();
        this.subject = null;
      } catch (e) {
        // ignore
      }
    }
  }

  public getConnectionState(): Observable<ConnectionState> {
    return this.connectionState;
  }

  public getSubject(): CelumReplaySubject<Message[]> {
    return this.subject;
  }
}
