import { Injectable } from '@angular/core';
import { AbortController } from '@azure/abort-controller';
import { finalize, from, mergeWith, Subject, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { FileUpload } from '../model/file-upload';
import { FileUploadConfig, UploadEngine } from '../model/upload-engine';

@Injectable({ providedIn: 'root' })
export class BlobUploadEngine implements UploadEngine {

  public prepareUpload(config: BlobUploadConfig, file: File): FileUpload {
    const abortController = new AbortController();

    const uploadedBytes$ = new Subject<number>();
    const upload$ = this.getBlobClient(config).pipe(
      switchMap(blobClient =>
        blobClient.uploadData(file, {
          abortSignal: abortController.signal,
          blobHTTPHeaders: { blobContentType: file.type },
          onProgress: progress => uploadedBytes$.next(progress.loadedBytes)
        })
      ),
      map(() => file.size),
      finalize(() => uploadedBytes$.complete()),
      mergeWith(uploadedBytes$)
    );

    return {
      start$: upload$,
      cancel: () => abortController.abort()
    };
  }

  // tslint:disable-next-line:typedef
  private getBlobClient(config: BlobUploadConfig) {
    return from(import('@azure/storage-blob')).pipe(
      map(module => {
        const blobServiceClient = new module.BlobServiceClient(`${config.protocol}//${config.host}/${config.sasToken}`, undefined, {
          retryOptions: { maxTries: config.maxTries ?? 1, retryDelayInMs: 400 }
        });
        const containerClient = blobServiceClient.getContainerClient(config.containerName);
        return containerClient.getBlockBlobClient(config.blobName);
      })
    );
  }

}

export interface BlobUploadConfig extends FileUploadConfig {
  protocol: string;
  host: string;
  sasToken: string;
  containerName: string;
  blobName: string;
  maxTries?: number;
}
