import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, Subject } from 'rxjs';
import { bufferTime, catchError, filter, mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import { CelumPropertiesProvider, ReactiveService, UUIDGenerator } from '@celum/core';

import { FileUpload } from '../model/file-upload';
import { FileUploadConfig } from '../model/upload-engine';
import { PrepareUploads, RemoveUpload, RemoveUploadQueue, StartUpload, UploadEvent, UploadEventData } from '../store/upload-actions';
import { getTicketProgressForQueue, UploadState } from '../store/upload-state';

export interface FileUploadRequest {
  config: FileUploadConfig;
  file: File;
}

@Injectable({ providedIn: 'root' })
export class UploadService extends ReactiveService {

  public static readonly BASE_QUEUE_IDENTIFIER = '@celum-base-queue-identifier';
  public static readonly MAX_CONCURRENT_UPLOADS = CelumPropertiesProvider.properties.maxConcurrentUploads; // property

  private queue: Map<string, File> = new Map();
  private fileUploads: Map<string, FileUpload> = new Map();

  private progressUpdates$ = new Subject<UploadEventData>(); // holds all progress updates (progress, failed, finished) from all uploads
  private workingQueue$ = new Subject<string>(); // holds all tickets which should be uploaded

  constructor(private store: Store<UploadState>) {
    super();
    this.handleUploads();
  }

  /**
   * @see prepareUploads
   */
  public prepareUpload(file: File, queueId: string = UploadService.BASE_QUEUE_IDENTIFIER): string {
    return this.prepareUploads([file], queueId)[0];
  }

  /**
   * Adds files to the given upload queue and returns a ticket which can be used to do the actual upload.
   * This 2-step process might be useful, if you e.g. want to limit how much uploads run concurrently as all can be prepared at the beginning but
   * only selected ones are uploaded.
   * @param files which should be uploaded
   * @param queueId optional - by default all tickets will land in the same queue but this can be customized.
   */
  public prepareUploads(files: File[], queueId: string = UploadService.BASE_QUEUE_IDENTIFIER): string[] {
    const ticketInfo: { ticket: string, size: number }[] = [];

    files.forEach(file => {
      const ticket = UUIDGenerator.generateId();
      this.queue.set(ticket, file);
      ticketInfo.push({
                        ticket,
                        size: file?.size ?? 0
                      });
    });

    this.store.next(new PrepareUploads(ticketInfo, queueId));
    return ticketInfo.map(ticketAndSize => ticketAndSize.ticket);
  }

  /**
   * Start the upload for given tickets.
   * Progress can be tracked by using selectors from upload-state.ts
   * @param tickets issues by prepareUpload
   * @param config with option to choose how file should be uploaded (e.g. with the HttpUploadEngine)
   */
  public upload(tickets: string[], config: FileUploadConfig): void {
    const existingTickets = tickets.filter(ticket => !!this.queue.get(ticket));
    this.store.next(new StartUpload(existingTickets));

    existingTickets.forEach(ticket => {
      const upload = config.engine.prepareUpload(config, this.queue.get(ticket));
      this.fileUploads.set(ticket, upload);
      this.workingQueue$.next(ticket);
    });
  }

  /**
   * Cancels and removes upload for given ticket
   * @param ticket of the upload that should be canceled
   */
  public removeUpload(ticket: string): void {
    this.cancelAndRemoveUpload(ticket);
    this.store.next(new RemoveUpload(ticket));
  }

  /**
   * Cancels and removes all uploads from a given upload queue.
   * @param queueId for which all uploads should be canceled
   */
  public removeUploadQueue(queueId: string): void {
    this.store.pipe(
      select(getTicketProgressForQueue(queueId)),
      take(1)
    ).subscribe(tickets => {
      tickets.forEach(ticket => this.cancelAndRemoveUpload(ticket.identifier));
      this.store.next(new RemoveUploadQueue(queueId));
    });
  }

  /**
   * 1) Listening on the working queue and only subscribes to the source of <MAX_CONCURRENT_UPLOADS> uploads at once.
   *    This avoids having too much concurrent subscriptions running (browsers anyways only allow 5-10 concurrent uploads).
   * 2) Is buffering progress updates from all uploads and notifies store in time intervals.
   *    This avoids having many small messages and instead batch them together to not need to spread store over and over again.
   */
  private handleUploads(): void {
    this.workingQueue$.pipe(
      mergeMap(ticket => this.executeUpload(ticket), UploadService.MAX_CONCURRENT_UPLOADS),
      takeUntil(this.unsubscribe$)
    ).subscribe();

    this.progressUpdates$.pipe(
      bufferTime(200),
      filter(events => events.length > 0),
      takeUntil(this.unsubscribe$)
    ).subscribe(events => this.store.next(new UploadEvent(events)));
  }

  private executeUpload(ticket: string): Observable<number> {
    const upload = this.fileUploads.get(ticket);

    return upload.start$.pipe(
      tap({
            next: progress => this.queueProgressUpdate(ticket, 'progress', progress),
            error: error => this.queueProgressUpdate(ticket, 'failed', undefined, error),
            complete: () => this.queueProgressUpdate(ticket, 'finished')
          }),
      catchError(err => {
        console.error('UploadService: Error', err);
        return EMPTY;
      })
    );
  }

  private queueProgressUpdate(ticket: string, type: 'progress' | 'failed' | 'finished', progress?: number, error?: any): void {
    this.progressUpdates$.next({
                                 ticket,
                                 type,
                                 progress,
                                 error
                               } as UploadEventData);
  }

  private cancelAndRemoveUpload(ticket: string): void {
    const upload = this.fileUploads.get(ticket);
    upload && upload.cancel();
    this.fileUploads.delete(ticket);
    this.queue.delete(ticket);
  }

}
