import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/**
 * Directive that can be applied to elements which should be focused automatically on appearance in the DOM.
 * The native autofocus attribute only works once on page-load, not on navigation inside a SPA or if an element is rendered conditionally (*ngIf).
 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLSelectElement/autofocus
 *
 * Usage
 * 1. Import CelumDirectivesModule into your local module
 * 2. Make sure that the element is focusable - https://allyjs.io/data-tables/focusable.html
 * 3. Add directive to element: <div celumAutofocus></div>
 */
@Directive({ selector: '[celumAutofocus]' })
export class AutofocusDirective implements AfterViewInit {

  /**
   * If set to true, all focus browser styles like borders, etc. will be removed from the element.
   * Note that hiding focus outlines is not accessible: https://www.a11yproject.com/posts/2013-01-25-never-remove-css-outlines/
   */
  @Input() public hideFocusOutline = true;

  constructor(private element: ElementRef) {}

  public ngAfterViewInit(): void {
    const nativeElement = this.element.nativeElement;

    if (this.hideFocusOutline) {
      nativeElement.style.outline = 'none';
    }

    this.element.nativeElement.focus();
  }
}
