import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { PortalConfiguration, PortalsProperties } from '@celum/portals/domain';

@Injectable({ providedIn: 'root' })
export class ConfigurationResolver implements Resolve<PortalConfiguration> {
  public configuration$ = new BehaviorSubject<PortalConfiguration>(undefined);

  constructor(private http: HttpClient, private router: Router) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<PortalConfiguration> {
    const id = route.paramMap.get('id');
    return this.fetchConfig(id).pipe(tap(config => this.configuration$.next(config)));
  }

  private fetchConfig(id: string): Observable<PortalConfiguration> {
    return this.http
      .get<PortalConfiguration>(`${PortalsProperties.properties.apiUrl}/configuration/${id}`)
      .pipe(tap({ error: err => this.handleFetchError(err) }));
  }

  private handleFetchError(error: HttpErrorResponse): void {
    const isPortalProtectionError = this.isPortalProtectionError(error);
    if (error.status !== 404 && !isPortalProtectionError) {
      console.error(`ConfigurationResolver: loading configuration failed unexpectedly!`, error);
    }
    if (!isPortalProtectionError) {
      this.router.navigateByUrl('not-found');
    }
  }

  private isPortalProtectionError(error: HttpErrorResponse): boolean {
    return error.status === 403 && !!error?.error?.orgId;
  }
}
