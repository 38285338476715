import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { fromEvent, Observable, timer } from 'rxjs';
import { filter, share, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ReactiveComponent } from '../util/reactive-component';

/**
 * Directive which can be used in conjunction with a mat-menu to show it directly on hover.
 *
 * Usage:
 * `<any-element matMenuOnHover [matMenuTriggerFor]="menu"></any-element>`
 * `<mat-menu #menu="matMenu">...</mat-menu>`
 */
@Directive({
             selector: '[matMenuOnHover]'
           })
export class MatMenuOnHoverDirective extends ReactiveComponent implements OnInit {

  /** Time between the hover and showing of the mat-menu in milliseconds */
  @Input() public matMenuOnHoverDelay = 750;
  /** Time between the showing of the mat-menu and the automatic vanishing */
  @Input() public matMenuOnHoverVanishTime = 500000000000; // Number.MAX_SAFE_INTEGER does not work (https://github.com/ReactiveX/rxjs/issues/6979)
  /** Specify selectors that will cause the menu to NOT open if elements for them are found in the dom */
  @Input() public matMenuOnHoverBlockingSelectors: string[] = [];

  constructor(private element: ElementRef, private menuTrigger: MatMenuTrigger) {
    super();
  }

  public ngOnInit(): void {
    if (!this.menuTrigger.menu) {
      // this might happen if the matMenu is applied conditionally by the use-case
      return;
    }

    this.menuTrigger.menu.hasBackdrop = false;

    const [enter$, leave$] = this.getEventListeners();

    enter$.pipe(
      switchMap(() => timer(this.matMenuOnHoverDelay).pipe(takeUntil(leave$))),
      filter(() => this.matMenuOnHoverBlockingSelectors.every(selector => !document.querySelector(selector))),
      tap(() => this.menuTrigger.openMenu()),
      switchMap(() => timer(this.matMenuOnHoverVanishTime).pipe(takeUntil(leave$))),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => this.menuTrigger.closeMenu());

    leave$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.menuTrigger.closeMenu());
  }

  // moved to separate method for better testability
  private getEventListeners(): [enter$: Observable<MouseEvent>, leave$: Observable<MouseEvent>] {
    const enter$ = fromEvent<MouseEvent>(this.element.nativeElement, 'mouseenter').pipe(share());
    const leave$ = fromEvent<MouseEvent>(this.element.nativeElement, 'mouseleave').pipe(share());
    return [enter$, leave$];
  }
}
