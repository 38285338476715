import { Inject, Injectable, InjectionToken } from '@angular/core';

import { HttpProxyInterceptor, HttpProxyInvoker as CoreProxyInvoker } from '@celum/core';

export const HTTP_PROXY_INTERCEPTOR_TOKEN = new InjectionToken<string>('HttpProxyInterceptor');

@Injectable({ providedIn: 'root' })
export class HttpProxyInvoker extends CoreProxyInvoker {

  constructor(@Inject(HTTP_PROXY_INTERCEPTOR_TOKEN) interceptor: HttpProxyInterceptor) {
    super([interceptor]);
  }
}
