import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { skip } from 'rxjs/operators';

import { CelumPropertiesProvider, LocalizedValue } from '@celum/core';

import { DateService } from '../util/date.service';
import { currentLocaleStream } from './current-locale-stream';
import { evaluateLocalizedValue, instantTranslate } from './translate-instant';

@Injectable({ providedIn: 'root' })
export class TranslationHelper {
  private static nativeTranslations: LocalizedValue = {
    en: 'English', de: 'Deutsch', fr: 'Français'
  };
  private availableLanguages: string[];

  constructor(private translate: TranslateService) {
  }

  public get locale(): string {
    return this.translate.currentLang;
  }

  public get browserLocale(): string {
    return this.translate.getBrowserLang();
  }

  public init(useLanguage?: string, availableLanguages?: string[], defaultLanguage?: string): void {
    this.availableLanguages = availableLanguages || CelumPropertiesProvider.properties.availableLanguages.map(loc => loc.locale);

    let defaultLang = defaultLanguage || CelumPropertiesProvider.properties.defaultLanguage;
    if (!this.availableLanguages.includes(defaultLang)) {
      defaultLang = this.availableLanguages[0];
    }
    this.translate.setDefaultLang(defaultLang);

    if (useLanguage && this.availableLanguages.includes(useLanguage)) {
      this.setLanguage(useLanguage);
    } else {
      // set default language
      this.translate.use(this.translate.defaultLang);
      DateService.GLOBAL_LOCALE = this.locale;
    }

    this.translate.onLangChange.pipe(skip(1)).subscribe(event => {
      DateService.GLOBAL_LOCALE = event.lang;
    });
  }

  public setLanguage(newLanguage: string): void {
    // remove cultural codes
    const newLang = newLanguage.split('-')[0];
    const locale = this.availableLanguages.find(language => language === newLang);

    if (locale && locale === this.translate.currentLang) {
      console.log('TranslationHelper: already using language ' + locale);
      DateService.GLOBAL_LOCALE = locale;
    } else if (locale) {
      console.log(`TranslationHelper: changing UI language from ${this.locale} to ${locale}`);
      this.translate.use(locale);
      DateService.GLOBAL_LOCALE = locale;
    } else {
      const defaultLang = this.translate.defaultLang;
      console.log(`TranslationHelper: selected locale ${newLang} not available! Using default locale: ${defaultLang}`);

      if (this.locale !== defaultLang) {
        console.log(`TranslationHelper: changing UI language from ${this.locale} to default locale: ${defaultLang}`);
        this.translate.use(defaultLang);
        DateService.GLOBAL_LOCALE = defaultLang;
      }
    }
  }

  /**
   * Returns a stream of the current ui - language. If it emits, it's guaranteed that the language files for the new language are already loaded
   */
  public currentLocaleStream(): Observable<string> {
    return currentLocaleStream(this.translate);
  }

  public getDefaultLocale(): string {
    return this.translate.defaultLang;
  }

  public instant(key: string | LocalizedValue): string;
  public instant(key: string | LocalizedValue, displayLanguage: string, defaultLanguage: string, targetLanguage?: string, useFallbacks?: boolean,
                 interpolationParams?: object): string;
  /**
   * Do an instant translation - this method is synchronous - you are responsible for knowing when the translations were loaded.
   * You can use the currentLocaleStream to find out when languages were loaded
   * @param key message-key or localized value
   * @param displayLanguage current ui-language
   * @param defaultLanguage default language (used as a fallback)
   * @param targetLanguage translate to specific language - if this is set - it will not fallback to other languages
   * @param useFallbacks Specify if translation should fall back to other languages if there is no translation for wanted one
   * @param interpolationParams which will be used if string is passed as string
   */
  public instant(key: string | LocalizedValue, displayLanguage?: string, defaultLanguage?: string, targetLanguage?: string, useFallbacks: boolean = true,
                 interpolationParams?: object): string {
    const useDisplayLanguage = displayLanguage ?? this.locale;
    const useDefaultLanguage = defaultLanguage ?? this.getDefaultLocale();
    return instantTranslate(this.translate, key, useDisplayLanguage, useDefaultLanguage, targetLanguage, useFallbacks, interpolationParams);
  }

  public static evaluateLocalizedValue(input: LocalizedValue, languageParam: string, displayLanguage: string, defaultLanguage: string,
                                       useFallbacks: boolean = true): string {
    return evaluateLocalizedValue(input, languageParam, displayLanguage, defaultLanguage, useFallbacks);
  }

  public static getUILocalesNativeTranslations(locales: string[]): LocalizedValue {
    return locales.reduce((obj, value) => ({...obj, [value]: this.nativeTranslations[value]}), {});
  }
}
