import { Pipe, PipeTransform } from '@angular/core';

export type FileSizeUnit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB';

/**
 * Translates given value. There are two different ways how this can happen:
 * If <code>options</code> is present, the value given with <code>from</code> will be translated into the value given with <code>to</code>.
 * If <code>options</code> is not present, the "optimal" size value will be chosen by the pipe.
 */
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {

  private units: FileSizeUnit[] = ['bytes', 'KB', 'MB', 'GB', 'TB'];

  /**
   * @param value     the value to transform
   * @param options   If <code>options</code> is present, the value given with <code>from</code> will be translated into the value given with <code>to</code>.
   * If <code>options</code> is not present, the "optimal" size value will be chosen by the pipe.
   * @returns translated <code>number</code> or a <code>string</code> with the according value (if no <code>options</code> is present)
   */
  public transform(value: number = 0, options?: { from?: FileSizeUnit, to?: FileSizeUnit, precision?: number }): number | string {
    const base = 1000;

    if (isNaN(parseFloat(String(value))) || !isFinite(value)) {
      return value;
    }

    if (options?.to && options?.from) {
      return this.createFromTo(options, value, base);
    } else {
      let magnitude = 4; // TB is highest magnitude

      for (let i = 0; i < this.units.length; i++) {
        if (value / Math.pow(base, i) < base) {
          magnitude = i;
          break;
        }
      }

      if (options?.precision) {
        return FileSizePipe.applyPrecision(value / Math.pow(base, magnitude), options.precision) + this.units[magnitude];
      } else {
        return Math.floor(value / Math.pow(base, magnitude)) + this.units[magnitude];
      }
    }
  }

  private createFromTo(options: { from?: FileSizeUnit; to?: FileSizeUnit; precision?: number }, value: number, base: number): number {
    const divergence: number = this.units.indexOf(options.from) - this.units.indexOf(options.to);
    const isNegative = divergence < 0;
    const steps = Math.abs(divergence);

    // If value or divergence is 0 we shouldn't convert it, so return the precised value
    if (value === 0 || divergence === 0) {
      return FileSizePipe.applyPrecision(value, options.precision);
    }

    const outputValue = isNegative ? value / (Math.pow(base, steps)) : value * (Math.pow(base, steps));

    // if precision isn't defined - returns pure value without rounding
    return FileSizePipe.applyPrecision(outputValue, options.precision);
  }

  private static applyPrecision(value: number, precision: number): number {
    const precisionSet = !!precision || precision === 0;
    return precisionSet ? parseFloat(value.toFixed(precision)) : value;
  }
}
