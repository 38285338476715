import { EnvironmentInjector, Injectable, Type } from '@angular/core';
import { tap } from 'rxjs';

import { addToMapWithIterableValue, DataUtil } from '@celum/core';

import { EntityResolver } from '../entity-resolver/entity-resolver';
import { ResultConsumerService } from '../entity-result/result-consumer.service';
import { Entity } from '../entity/entity';
import { EntityRegistrationDeviations, EntityRegistry, ResolveConfig, ResolveStrategy, ResolveStrategyDeviation } from '../entity/entity-registry';

type IdsForResolver = Map<Type<EntityResolver>, Set<string>>;

@Injectable({ providedIn: 'root' })
export class EntityResolverService {
  constructor(private environmentInjector: EnvironmentInjector, private resultConsumer: ResultConsumerService) {}

  /**
   * Check all given {@param entities}, evaluate which resolver relevant properties changed compared to {@param oldEntitiesById} and call those resolvers
   * @param entities which were sent from the backend and are about to be added into the store
   * @param oldEntitiesById snapshot of entities before {@param entities} are added to the store - unused at the moment but will be needed in the future
   * @param deviations defined by the concrete use case loading the {@param entities}
   */
  public resolve(entities: Entity[], oldEntitiesById: Record<string, Entity>, deviations: EntityRegistrationDeviations): void {
    const idsForResolvers = this.registerIdsToResolve(entities, deviations);
    this.callResolvers(idsForResolvers);
  }

  /** Loop over each entity, evaluate the properties relevant to resolvers and return the resolver types with ids that need to be resolved. */
  private registerIdsToResolve(entities: Entity[], deviations?: EntityRegistrationDeviations): IdsForResolver {
    const idsForResolver = new Map<Type<EntityResolver>, Set<string>>();
    entities.forEach(entity => {
      const resolveStrategy = EntityResolverService.getResolveStrategy(entity.typeKey, deviations);
      Object.entries(resolveStrategy)
            .filter(([_, config]) => config.resolver)
            .forEach(([key, resolveConfig]) => {
              addToMapWithIterableValue(idsForResolver, resolveConfig.resolver, entity[key as keyof Entity], new Set());
            });
    });
    return idsForResolver;
  }

  private callResolvers(idsForResolver: IdsForResolver): void {
    idsForResolver.forEach((ids, resolverType) => this.callOneResolver(ids, resolverType));
  }

  private callOneResolver(ids: Set<string>, resolverType: Type<EntityResolver>): void {
    const filteredIds = [...ids].filter(item => !!item);
    if (DataUtil.isEmpty(filteredIds)) {
      return;
    }

    const resolver = this.environmentInjector.get(resolverType, null);
    if (!resolver) {
      console.error(`EntityResolverService: Resolver "${resolverType.name}" cannot be injected. Please make sure that it is provided.`);
      return;
    }

    const resolverCall = resolver.getMany(filteredIds);
    this.resultConsumer.consume(resolverCall.response$, resolverCall.metaInfo).pipe(tap({
                                                                                          error: err => console.error(
                                                                                            `EntityResolverService: Call to resolver "${resolverType.name}" failed.`, ids, err)
                                                                                        })).subscribe();
  }

  /**
   * Combine the default resolveStrategy from the given entity {@param typeKey} with the given {@param deviations}.
   * Also unify the shape of the resolve configuration to always use a {@see ResolveConfig} as value.
   */
  private static getResolveStrategy(typeKey: string, deviations: EntityRegistrationDeviations): ResolveStrategy<Entity, ResolveConfig> {
    const rawStrategy = EntityRegistry.get(typeKey).resolveStrategy;
    const rawDeviation = deviations?.[typeKey]?.resolveStrategy;

    const strategy = !rawDeviation || rawDeviation.inheritStrategy ? EntityResolverService.unifyStrategy(rawStrategy) : {};
    const deviation = EntityResolverService.unifyStrategy(rawDeviation);
    return { ...strategy, ...deviation };
  }

  /** Get a strategy or deviation, remove a possible "inheritStrategy" property and transform all shortcuts (Type<EntityResolver>) to a {@see ResolveConfig} */
  private static unifyStrategy(strategy: ResolveStrategy | ResolveStrategyDeviation<any>): ResolveStrategy<Entity, ResolveConfig> {
    if (!strategy) {
      return {};
    }

    const copy = { ...strategy } as ResolveStrategy;
    delete (copy as any).inheritStrategy;

    return Object.fromEntries(
      Object.entries(copy).map(([key, resolverOrConfig]) => {
        const asConfigObject = typeof resolverOrConfig === 'function' ? { resolver: resolverOrConfig } : { resolver: null, ...resolverOrConfig };
        return [key, asConfigObject];
      })
    );
  }
}
