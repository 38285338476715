export interface NamedQuery {

  getEndPoint(): string;

  getQueryName(): string;

  getHttpMethod(): HttpMethod;
}

// tslint:disable-next-line:no-empty-interface TODO remove!
export interface PageableNamedQuery extends NamedQuery {

}

export enum HttpMethod {
  POST,
  GET
}
