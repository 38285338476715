import { Injectable } from '@angular/core';

import { CelumPropertiesProvider } from '@celum/core';

import { LicenseType } from '../model/user.model';

@Injectable()
export class LicenseService {
  private internalApplicationLicense: LicenseType | null;

  public get applicationLicense(): LicenseType | null {
    if (this.internalApplicationLicense === undefined) {
      this.internalApplicationLicense = this.loadApplicationLicense();
    }
    return this.internalApplicationLicense;
  }

  private loadApplicationLicense(): LicenseType | null {
    const configuredLicense = CelumPropertiesProvider.properties.authentication.applicationLicenseType;
    if (!Object.values(LicenseType).some(lic => lic === configuredLicense)) {
      console.error('LicenseService: No valid applicationLicenseType set in application properties. Please set a valid license type.');
      return null;
    } else {
      return configuredLicense as unknown as LicenseType;
    }
  }
}
