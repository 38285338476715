import { DOCUMENT } from '@angular/common';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import {
  AuthInterceptor,
  AuthInterceptorConfig,
  AuthService,
  ServiceAccessTokenProvider,
  ServiceTokenInterceptorConfig,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  TenantService
} from '@celum/authentication';
import { TranslationHelper } from '@celum/ng2base';
import { PortalsProperties } from '@celum/portals/domain';

@Injectable()
export class PortalsAuthInterceptor extends AuthInterceptor {
  private readonly portalConfigUrl = `${PortalsProperties.properties.apiUrl}/configuration/`;

  constructor(
    authService: AuthService,
    protected router: Router,
    @Inject(DOCUMENT) document: Document,
    serviceAccessTokenProvider: ServiceAccessTokenProvider,
    translation: TranslationHelper,
    @Inject(SERVICE_TOKEN_INTERCEPTOR_CONFIG) interceptorConfig: ServiceTokenInterceptorConfig<AuthInterceptorConfig>,
    private tenantService: TenantService
  ) {
    super(authService, router, document, serviceAccessTokenProvider, translation, interceptorConfig);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Initial config request? Used to determine whether all calls need to be authenticated
    if (req.url.startsWith(this.portalConfigUrl)) {
      // already stored? Check whether we need authentication
      const tenantId = this.tenantService.getCurrentTenantId();
      if (tenantId) {
        return super.intercept(req, next).pipe(catchError(error => this.handleUnauthorizedError(error)));
      }
      // Not stored? Make the request normally. If it fails with a 403 we will store the tenantId/portalId and return after login, ready to add the token
      return next.handle(req);
    }

    // All other calls should only be authenticated if the portal is protected
    // During login we don't yet have the portal configuration, that's why we also have to check whether we stored the tenantId
    return !!this.tenantService.getCurrentTenantId()
      ? super.intercept(req, next).pipe(catchError(error => this.handleUnauthorizedError(error)))
      : next.handle(req);
  }

  private handleUnauthorizedError(error: any): Observable<never> {
    // Catch and handle the 403 specifically - in that case the user has no access to the portal or organization, so we redirect to "not-found"
    if (error.status === 403 && !error?.error?.orgId) {
      this.router.navigateByUrl('/not-found');
      return EMPTY;
    }
    return throwError(() => error);
  }
}
