import { Subject } from 'rxjs';

/**
 * Base class for reactive services. Use it if you have to manage subscriptions manually as it provides common reactive patterns.
 */
export abstract class ReactiveService {

  /**
   * Emits to notify that subscribers should unsubscribe
   */
  protected unsubscribe$: Subject<void>;

  constructor() {
    this.initUnsubscribe();
  }

  /**
   * When a service is destroyed, notify subscribers and complete
   */
  public destroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected initUnsubscribe(): void {
    this.unsubscribe$ = new Subject();
  }

}
