import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

import { DataUtil } from '@celum/core';

export function dateInFuture(c: AbstractControl): any {
  let validationResult = true;

  if (!DataUtil.isEmpty(c.value)) {
    validationResult = new Date().getTime() < new Date(c.value).getTime();
  } else {
    validationResult = true;
  }

  return (validationResult) ? null : {
    dateInFuture: {
      valid: false,
      messageKey: 'VALIDATION.DATE_IN_FUTURE',
      params: {}
    }
  };
}

@Directive({
             selector: '[dateInFuture][ngModel]',
             providers: [
               {
                 provide: NG_VALIDATORS,
                 useValue: dateInFuture,
                 multi: true
               }
             ]
           })
export class DateInFutureValidator {

}
