import { defer, Observable, of, throwError, timer } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, map, mergeMap, pairwise, retryWhen, shareReplay, startWith, switchMap, take } from 'rxjs/operators';

import { CelumPropertiesProvider } from '../../core/configuration/celum-properties-provider';
import { isTruthy } from '../../core/rxjs/isTruthy';
import { CelumReplaySubject } from '../async/celum-replay-subject';
import { CelumSubject } from '../async/celum-subject';
import { SimpleCelumSubject } from '../async/simple-celum-subject';
import { CommunicationException } from '../model/error';
import { Message } from '../model/message';
import { HttpMethod } from '../model/named-query';
import { ConnectionState } from './connection-checker';
import { HttpProxyInterceptor } from './http-proxy-invoker';

export const COMM_EXCEPTION = 'ERROR.COMMUNICATION';

export class HttpHandler {

  private readonly connectionState$: Observable<ConnectionState>;
  private readonly scalingDuration: number;
  private readonly retries: number;

  constructor(private interceptors: HttpProxyInterceptor[], private isConnected$: Observable<boolean>) {
    this.connectionState$ = this.isConnected$
                                .pipe(startWith(null), // just to have some first "old" value
                                      pairwise(),
                                      // tslint:disable-next-line:triple-equals
                                      filter(([old, current]) => (old != null && old != current) || !current),
                                      // emit only if old is already set or if current is false (-> connection lost) or if they are different
                                      map(([old, current]) => current ? ConnectionState.CONNECTION_RESTORED :
                                                              ConnectionState.CONNECTION_LOST));

    this.scalingDuration = CelumPropertiesProvider.properties.queryRetryDelay;
    this.retries = CelumPropertiesProvider.properties.queryRetries;
  }

  public executeHttpCall(url: string, name: string, method: HttpMethod, retry: boolean, data: any, expectResult: boolean = true): Observable<CelumSubject> {
    if (HttpMethod.GET === method) {
      return this.executeGetCall(url, name, retry, data);
    } else if (HttpMethod.POST === method) {
      return this.executePostCall(url, name, retry, data, expectResult);
    }

    console.error(`HttpHandler: invalid method ${method} specified for call '${name}' to url '${url}'!`);
    return throwError(new CommunicationException(COMM_EXCEPTION));
  }

  public executePostCall(url: string, name: string, retry: boolean, data: any, expectResult: boolean = true): Observable<CelumSubject> {
    return this.executeCall(url, name, 'POST', retry, data, expectResult);
  }

  public executeGetCall(url: string, name: string, retry: boolean, data: any): Observable<CelumSubject> {
    return this.executeCall(url, name, 'GET', retry, data, true);
  }

  // http://reactivex.io/rxjs/file/es6/observable/dom/AjaxObservable.js.html#lineNumber78
  protected callInternal(url: string, data: any, method: string): Observable<AjaxResponse<any>> {
    const headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    };

    this.interceptors.forEach(interceptor => interceptor?.intercept(url, data, method, headers));

    return ajax({
                  url,
                  body: data,
                  async: true,
                  method,
                  headers
                });
  }

  private executeCall(url: string, name: string, method: string, retry: boolean, data: any, expectResult: boolean): Observable<CelumSubject> {
    if (CelumPropertiesProvider.properties.logSendMessages) {
      console.log(`HttpHandler: Execute ${method} request to ${url} for query ${name}`);
    }

    let callResult$ = defer(() => this.callInternal(url, data, method));

    if (retry) {
      callResult$ = callResult$.pipe(
        retryWhen(retryStrategy(this.isConnected$, name, {
          scalingDuration: this.scalingDuration,
          maxRetryAttempts: this.retries
        })));
    } else {
      callResult$ = callResult$.pipe(catchError(err => {
        let dataString = '';
        try {
          dataString = JSON.stringify(data);
        } catch (e) {
          // just ignore it..
        }

        console.error(`HttpHandler: Error on request '${name}' on url '${url}' with data ${dataString}.`, err);
        return throwError(new CommunicationException(getMessageOrMessageKey(err), err));
      }));
    }

    return callResult$.pipe(map(res => {
      if (res.status === 200) {
        return expectResult ? this.handleResult(res.response, name) :
               new SimpleCelumSubject(of<Message[]>() as CelumReplaySubject<Message[]>, this.connectionState$);
      } else {
        // TODO everything else...
        console.warn(`HttpHandler: Error on request '${name}'.`);

        return null;
      }
    }));
  }

  private handleResult(data: any, name: string): CelumSubject {
    if (data) {
      if (CelumPropertiesProvider.properties.logCommunication) {
        console.log('Got response', name, data);
      } else if (CelumPropertiesProvider.properties.logSendMessages) {
        console.log('Got response', name);
      }

      let innerSubject: Observable<Message[]>;

      try {
        let list: Message[] = [];

        if (Array.isArray(data)) {
          list = data.map(resObj => ({ data: resObj }));
        } else {
          list.push({ data });
        }

        innerSubject = of(list).pipe(shareReplay(1));
      } catch (e) {
        console.error('HttpHandler: Failed to create subject from response for query ' + name, e);
        throw new CommunicationException(COMM_EXCEPTION, e);
      }

      return new SimpleCelumSubject(innerSubject as CelumReplaySubject<Message[]>, this.connectionState$);
    } else {
      console.error(`HttpHandler: Failed to load query ${name}. Reason: no data`);
      throw new CommunicationException(COMM_EXCEPTION);
    }
  }
}

function getMessageOrMessageKey(error: any): string {
  let message = COMM_EXCEPTION;

  if (error.response) {
    message = error.response.messageKey || error.response.message;
  }
  return message;
}

function handleRedirects(error: any): void {
  // on 401 error, redirect to specific url if something is configured
  if (error.status === 401 && CelumPropertiesProvider.properties.redirectToUrlOn401.length > 0) {
    window.location.href = CelumPropertiesProvider.properties.redirectToUrlOn401;
  }

  if (error.status === 403 && CelumPropertiesProvider.properties.redirectToSelfOn403) {
    // noinspection SillyAssignmentJS
    window.location.href = window.location.href;
  }
}

// retry after 1s, 2s, etc...
function executeAgainAfterDelayOrReconnect(isConnected$: Observable<boolean>, name: string, retryAttempt: number, scalingDuration: number): Observable<any> {
  return isConnected$.pipe(take(1), switchMap(connected => {
    if (connected) {
      console.warn(`HttpHandler: Failed to execute call for "${name}" try again soon...`);

      // if connected, just delay
      return timer(retryAttempt * scalingDuration);
    } else {
      console.debug(`HttpHandler: Error executing command/query ${name}, connection currently broken. Wait for re-connect to proceed...`);

      // if not connected, wait until connection is established again
      return isConnected$.pipe(isTruthy(), map(() => void 0), take(1));
    }
  }));
}

export const retryStrategy = (isConnected$: Observable<boolean>, name: string,
                              {
                                maxRetryAttempts = 3,
                                scalingDuration = 200
                              }: { maxRetryAttempts?: number, scalingDuration?: number } = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      handleRedirects(error);

      const message = getMessageOrMessageKey(error);

      // no retry for status codes below 500 (exclude 0 because this is connection loss!)
      if ((error.status < 500 || CelumPropertiesProvider.properties.queryRetryForConnectionLossOnly) && error.status !== 0) {
        return throwError(new CommunicationException(message, error));
      }

      const retryAttempt = i + 1;

      // if maximum number of retries have been met (only if this is not connection lost!)
      // or response is a status code we don't wish to retry, throw error
      if (error.status !== 0 && retryAttempt > maxRetryAttempts) {
        return throwError(new CommunicationException(message, error));
      }

      console.log(`HttpHandler: Attempt ${retryAttempt} on ${name} retrying in ${retryAttempt * scalingDuration}ms`);

      return executeAgainAfterDelayOrReconnect(isConnected$, name, retryAttempt, scalingDuration);
    })
  );
};
