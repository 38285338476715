import { defer as observableDefer, from, Observable, of, OperatorFunction } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';

import { CelumPropertiesProvider } from '../../core';
import { CelumSubject } from '../async/celum-subject';
import { Command } from '../model/command';
import { CommunicationException } from '../model/error';
import { HttpMethod, NamedQuery, PageableNamedQuery } from '../model/named-query';
import { NewObjectGraphResult } from '../result/new-object-graph-result';
import { NewPagedObjectGraphResult } from '../result/new-paged-object-graph-result';
import { ObjectGraphResultHelper } from '../result/object-graph-result-helper';
import { ConnectionChecker } from './connection-checker';
import { HttpHandler } from './http-handler';
import { HttpProxyInterceptor } from './http-proxy-invoker';

export class HttpProxy {

  private readonly connectionState$: Observable<boolean>;
  private httpHandler: HttpHandler;

  constructor(interceptors: HttpProxyInterceptor[]) {
    const connectionChecker = this.createConnectionChecker();

    const onlineStateCheck$ = CelumPropertiesProvider.properties.connectionCheck ? connectionChecker.checkForOnlineState(null).pipe(shareReplay(1)) : of(true);

    onlineStateCheck$.pipe(filter(connected => !connected)).subscribe(() => {
      console.error('Connection lost!');
      throw new CommunicationException('Server is not available');
    });

    this.connectionState$ = onlineStateCheck$;

    this.httpHandler = new HttpHandler(interceptors, onlineStateCheck$);
  }

  public isConnected(): Observable<boolean> {
    return this.connectionState$;
  }

  public executeCommand(baseAddress: string, command: Command, expectResult: boolean = true): Observable<CelumSubject> {
    return this.httpHandler.executePostCall(baseAddress + command.getEndPoint(), command.getCommandName(), false, command, expectResult);
  }

  public executeCommandWithGraphResult(baseAddress: string, command: Command): Observable<NewObjectGraphResult> {
    // tslint:disable-next-line:no-parameter-reassignment
    baseAddress = baseAddress + command.getEndPoint();

    return observableDefer(() => this.httpHandler.executeHttpCall(baseAddress, command.getCommandName(), HttpMethod.POST, false, command))
      .pipe(this.mapToGraphResult(baseAddress, command.getCommandName(), command, false, -1, HttpMethod.POST, false, false));
  }

  public query<Q extends NamedQuery>(baseAddress: string, query: Q, name: string): Observable<CelumSubject> {
    // tslint:disable-next-line:no-parameter-reassignment
    baseAddress = baseAddress + query.getEndPoint();

    return observableDefer(() => this.httpHandler.executeHttpCall(baseAddress, name, query.getHttpMethod(), true, query));
  }

  // emits after all results have arrived
  public queryAsGraph<Q extends NamedQuery>(baseAddress: string, query: Q, autoUpdate?: boolean, name?: string,
                                            customUpdateInterval?: number): Observable<NewObjectGraphResult> {
    return this.query(baseAddress, query, name)
               .pipe(this.mapToGraphResult(baseAddress + query.getEndPoint(), name, query, autoUpdate, customUpdateInterval, query.getHttpMethod(), true));
  }

  public mapToGraphResult(url: string, name: string, data: any, autoUpdate: boolean, customUpdateInterval: number, method: HttpMethod, retry: boolean,
                          enableUpdate: boolean = true): OperatorFunction<CelumSubject, NewObjectGraphResult> {
    return map((celumSubject: CelumSubject) => {
      const resultHelper = new ObjectGraphResultHelper(celumSubject, name, autoUpdate, enableUpdate ? this.updateQuery(url, name, method, retry, data) : null);
      const result = new NewObjectGraphResult(resultHelper, autoUpdate, name);
      result.initialize();

      // make sure that no one is polling every millisecond...
      result.setUpdateInterval(
        (customUpdateInterval && customUpdateInterval > 1000) ? customUpdateInterval : CelumPropertiesProvider.properties.liveUpdateInterval);

      return result;
    });
  }

  // emits after all results have arrived
  public queryAsPagedGraph<Q extends PageableNamedQuery>(baseAddress: string, queryBuilder: (offset: number, limit: number) => Promise<Q>, limit: number,
                                                         startWithOffset: number = 0, autoUpdate?: boolean, name?: string,
                                                         customUpdateInterval?: number): Observable<NewPagedObjectGraphResult> {
    return observableDefer(() =>
                             from(queryBuilder(startWithOffset, limit)).pipe(switchMap(query => {
                               const address = baseAddress + query.getEndPoint();
                               return this.httpHandler.executeHttpCall(address, name, query.getHttpMethod(), true, query);
                             })))
      .pipe(this.mapToPagedGraphResult(queryBuilder, baseAddress, name, autoUpdate, startWithOffset, limit, customUpdateInterval));
  }

  public mapToPagedGraphResult<Q extends PageableNamedQuery>(queryBuilder: (offset: number, limit: number) => Promise<Q>, baseAddress: string, name: string,
                                                             autoUpdate: boolean, startWithOffset: number, limit: number,
                                                             customUpdateInterval: number): OperatorFunction<CelumSubject, NewPagedObjectGraphResult> {
    return map(celumSubject => {
      const resultHelper = new ObjectGraphResultHelper(celumSubject, name, autoUpdate, this.pagedUpdateQuery(queryBuilder, baseAddress, name));
      const result = new NewPagedObjectGraphResult(resultHelper, startWithOffset, limit, autoUpdate, name);
      result.initialize();

      // make sure that no one is polling every millisecond...
      result.setUpdateInterval(
        (customUpdateInterval && customUpdateInterval > 1000) ? customUpdateInterval :
        CelumPropertiesProvider.properties.liveUpdateInterval);

      return result;
    });
  }

  protected createConnectionChecker(): ConnectionChecker {
    return new ConnectionChecker();
  }

  protected updateQuery(url: string, name: string, method: HttpMethod, retry: boolean, data: any): () => Promise<CelumSubject> {
    return () => this.httpHandler.executeHttpCall(url, name, method, true, data).toPromise();
  }

  protected pagedUpdateQuery<Q extends PageableNamedQuery>(queryBuilder: (offset: number, limit: number) => Promise<Q>, baseAddress: string,
                                                           name: string): (offset: number, limit: number) => Promise<CelumSubject> {
    return (offset: number, limit: number) => queryBuilder(offset, limit).then(query => {
      const data = query;

      const url = baseAddress + query.getEndPoint();

      return this.httpHandler.executeHttpCall(url, name, query.getHttpMethod(), true, data).toPromise();
    });
  }

}
