import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { isTruthy } from '@celum/core';

import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

/**
 * @deprecated
 * Updating the tenantId directly after login is now done via the _UserService_. This component will be removed in a future release.
 */
@Component({
  selector: 'celum-auth-login-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
  standalone: true
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService, private userService: UserService, private activatedRoute: ActivatedRoute) {}
  public ngOnInit(): void {
    /* Special use case: If a user is logged out due to inactivate (after about one day), they are presented with the login-screen where the email is already selected.
       If the user clicks on the "back"-button (which normally would allow changing the email-address), they are instead redirected to the same screen, so they cannot
       ever change the email address (unless they log in and out again). However, when clicking onto the "back"-button the login-page redirects to
       "[libraryUrl]/logged-in?cancelled=true", so we can react and force a complete logout in this use case */
    this.activatedRoute.queryParams
      .pipe(
        switchMap(params => {
          if (params?.cancelled === 'true') {
            // Logout and redirect to login page. Make sure to interrupt the execution of the rest of the stream
            this.authService.signOut().subscribe();
            return EMPTY;
          }
          return of(undefined);
        }),
        // If no cancelled parameter is provided, we now need to store an initially selected tenant. Once the user is logged in, we take the first tenant from the
        // list from the backend and store it, then redirect to the main page
        switchMap(() => this.authService.isAuthenticated$),
        isTruthy(),
        take(1),
        switchMap(() => this.userService.loadCurrentUser())
      )
      .subscribe({ complete: () => window.location.assign('/') });
  }
}
