import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Looks for links in the passed string and adds the appropriate html tags.
 *
 * Sanitization: This pipe uses the {@link DomSanitizer} to sanitize the passed string and the result after transformation right before it returns {@link SafeHtml}.
 */
@Pipe({
        name: 'parseLinks',
        pure: true
      })
export class ParseLinksPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  public transform(text: string, limit: number, showTooltip: boolean = true, showEmbeddedTagsAsText: boolean = true): SafeHtml {

    // first, sanitize in general. then handle links.
    const sanitizedValue: string = this.sanitizer.sanitize(SecurityContext.HTML, text);
    // specify a default MINIMUM value which makes sense
    const length: number = limit && limit >= 30 ? limit : 30;

    const parsedLinks = ParseLinksPipe.parseLinks(sanitizedValue, length, showTooltip);

    // sanitize the processed text  again, just to make sure this pipe doesn't introduce any security issues
    const sanitizedParsedLinks = this.sanitizer.sanitize(SecurityContext.HTML, parsedLinks);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedParsedLinks);
  }

  // note: we can use this for @mention as well... might be a bit more complicated (eg. passing json objects like linkify.js does for @mention support) but...
  // note 2: for sure we could do the same with weird reg expressions... at least for now. downside: no shortening possible
  // note 3: sample regex (if we ever want to validate/replace by) -> https://stackoverflow.com/questions/43341426/validate-url-with-angular-and-html-5
  private static parseLinks(text: string, limit: number, showTooltip: boolean): string {
    const lineBreak = '<br>';

    if (text && text.length > 0) {
      const textArray: string[] = [];

      // split text items...
      for (const textPart of text.split(/\s|&#10;/)) {
        if (textPart === lineBreak) {
          textArray.push(lineBreak);
          continue;
        }

        textArray.push(this.handleLinks(textPart, showTooltip, limit).join(lineBreak));
      }

      // join text items again
      return textArray.join(' ');
    }

    return text;
  }

  private static handleLinks(text: string, showTooltip: boolean, limit: number): string[] {
    const partsArray: string[] = [];
    for (const textPart of text.split('<br>')) {
      if (textPart.startsWith('www.') || textPart.startsWith('http://') || textPart.startsWith('https://')) {
        const hrefLink = textPart.startsWith('www.') ? `http://${textPart}` : textPart; // in case of www we must append http:// in order to make the
        // link clickable
        partsArray.push(ParseLinksPipe.buildLinkString(textPart, hrefLink, showTooltip, limit));
      } else {
        partsArray.push(textPart);  // no link or similar - just add the item in correct order
      }
    }
    return partsArray;
  }

  private static buildLinkString(link: string, hrefLink: string, showTooltip: boolean, limit: number): string {
    const title = showTooltip ? `title='${link}' ` : '';
    return `<a href='${hrefLink}' target='_blank' ${title}class='external-link'>${ParseLinksPipe.shortLink(link, limit)}</a>`;
  }

  private static shortLink(text: string, limit: number): string {
    return text.length > limit ? `${text.substring(0, limit - 10)}...${text.substring(text.length - 7)}` : text;
  }

}
