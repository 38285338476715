import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import { LogLevel } from '@azure/msal-browser';

import { AuthGuard } from './guards/auth.guard';
import { MSALGuardConfigFactory, MSALInstanceFactory } from './msal-configuration-factories';
import { AuthService } from './services/auth.service';
import { LicenseService } from './services/license.service';
import { LoginRedirectService } from './services/login-redirect.service';
import { ServiceAccessTokenProvider } from './services/service-access-token-provider.service';
import { TenantService } from './services/tenant.service';
import { UserService } from './services/user.service';

export interface CelumAuthModuleConfig {
  logLevel?: LogLevel;
}

@NgModule({
  imports: [
    // basic angular modules
    CommonModule,
    // MSAL modules
    MsalModule
  ]
})
export class CelumAuthModule {
  public static forRoot(config?: CelumAuthModuleConfig): ModuleWithProviders<CelumAuthModule> {
    return {
      ngModule: CelumAuthModule,
      providers: [
        AuthGuard,
        AuthService,
        LicenseService,
        LoginRedirectService,
        ServiceAccessTokenProvider,
        MsalService,
        {
          provide: MSAL_INSTANCE,
          useValue: MSALInstanceFactory(config?.logLevel)
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
        TenantService,
        UserService
      ]
    };
  }
}
