import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { CelumSubject } from '../async/celum-subject';
import { NewObjectGraphResult } from '../result/new-object-graph-result';
import { ConnectionState } from './connection-checker';

/**
 * Handles automatic pause and resume of an ObjectGraphResult on connection loss and connection restored. Only if autoUpdate is true and the celumSubject is
 * not already stopped.
 */
export class ConnectionLostHandler {

  private unsubscribe$ = new Subject<void>();
  private resumeSubscription: Subscription;

  constructor(celumSubject: CelumSubject, private autoUpdate: boolean, private graphResult: NewObjectGraphResult, private name: string,
              private update: () => any) {
    this.init(celumSubject, graphResult, autoUpdate, name, update);
  }

  public updateSubject(newSubject: CelumSubject): void {
    this.resumeSubscription && this.resumeSubscription.unsubscribe();

    this.resumeSubscription = this.handleConnectionRestored(newSubject, this.graphResult, this.autoUpdate, this.name, this.update);
  }

  public destroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private init(celumSubject: CelumSubject, graphResult: NewObjectGraphResult, autoUpdate: boolean, name: string, update: () => any): void {
    // may have stopped already due to an error...
    if (!celumSubject.getSubject().isStopped) {
      this.resumeSubscription = this.handleConnectionRestored(celumSubject, graphResult, autoUpdate, name, update);

      const disconnectedStats = [ConnectionState.CONNECTION_LOST, ConnectionState.CONNECTION_CLOSED];
      celumSubject.getConnectionState().pipe(filter(state => disconnectedStats.indexOf(state) >= 0),
                                             distinctUntilChanged(),
                                             takeUntil(this.unsubscribe$))
                  .subscribe(() => {
                    // if auto update or the subject is not yet destroyed... stop updating the query
                    if (!celumSubject.getSubject().isStopped) {
                      console.debug(`Connection lost, pause querying '${name}'`);
                      graphResult.pauseUpdates();
                    }
                  });
    }
  }

  private handleConnectionRestored(celumSubject: CelumSubject, graphResult: NewObjectGraphResult, autoUpdate: boolean, name: string,
                                   update: () => any): Subscription {
    return celumSubject.getConnectionState()
                       .pipe(filter(state => ConnectionState.CONNECTION_RESTORED === state), takeUntil(this.unsubscribe$))
                       .subscribe(() => {
                         if (!graphResult.isInitialProcessingComplete() || autoUpdate) {
                           console.debug(`Connection restored, execute query '${name}' again.`);
                           update();
                         }

                         graphResult.resumeUpdates(false);
                       });
  }
}
