import { BehaviorSubject, EMPTY, merge, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Message } from '../model/message';
import { PaginationInformation } from '../model/pagination-information';
import { PaginationResult } from '../model/pagination-result';
import { GraphResultProcessor } from './graph-result-processor';
import { NewObjectGraphResult } from './new-object-graph-result';
import { ObjectGraphResultHelper } from './object-graph-result-helper';
import { PagedGraphResultProcessor } from './paged-graph-result-processor';

const PAGINATION_INFO = 'paginationInformation';

export class NewPagedObjectGraphResult extends NewObjectGraphResult {

  public hasBottom$ = new BehaviorSubject(false);
  public hasTop$ = new BehaviorSubject(false);
  public total$ = new BehaviorSubject(0);

  private lastLimit: number;
  private batchSize: number;
  private lastOffset: number;
  private minOffset = 0;
  private maxOffset = 0;
  private maxIndex = 0;

  private fetchPending = false;
  private paginationResult: PaginationResult;

  constructor(resultHelper: ObjectGraphResultHelper, initialOffset: number, initialLimit: number, autoUpdate?: boolean, name: string = '') {
    super(resultHelper, autoUpdate, name);

    this.minOffset = initialOffset;
    this.maxOffset = initialOffset;
    this.maxIndex = initialOffset + initialLimit;
    this.lastOffset = initialOffset;
    this.lastLimit = initialLimit;
    this.batchSize = initialLimit;
  }

  public fetch(offset: number, limit: number): Promise<boolean> {
    if (offset < 0 || limit <= 0) {
      return Promise.reject<boolean>('Offset may not be < 0 and limit may not be <= 0!');
    }

    console.debug('PagedObjectGraphResult: fetch ', offset, limit);

    if (this.alreadyFetched(offset, limit)) {
      console.log('PagedObjectGraphResult: already fetched the requested part. Do nothing.');
      return Promise.resolve(false);
    }

    if (this.destroyed) {
      console.warn('PagedObjectGraphResult: ObjectGraph already destroyed. Cannot execute another fetch.');
      return Promise.resolve(false);
    }

    if (this.fetchPending) {
      console.log('PagedObjectGraphResult: fetch is already pending. Do nothing.');
      return Promise.resolve(false);
    }

    return this.doFetchInternal(offset, limit);
  }

  public updateBatchSize(batchSize: number): void {
    if (this.batchSize < batchSize) {
      // new batchSize is higher than the older one, so fetch the "missing" items... regardless of on which page we currently are
      // TODO mru this does not consider frequent changes of batch size from smaller to bigger...
      this.fetch(this.lastOffset + this.lastLimit, (batchSize - this.batchSize));
    }

    this.batchSize = batchSize;
  }

  public registerOnPaginationInfoReceived(): Observable<PaginationResult> {
    const currentInformation$ = this.paginationResult ? of(this.paginationResult) : EMPTY;
    const futureInformation$ = this.eventsSubject.pipe(filter(event => event.type === PAGINATION_INFO), map(event => event.data));
    return merge(currentInformation$, futureInformation$);
  }

  public fetchNext(): Promise<boolean> {
    return this.fetch(this.lastOffset + this.lastLimit, this.batchSize);
  }

  public fetchTopNext(limit: number): Promise<boolean> {
    return this.fetch(Math.max(this.minOffset - limit, 0), limit);
  }

  public fetchBottomNext(limit: number): Promise<boolean> {
    return this.fetch(this.lastOffset + this.lastLimit, limit);
  }

  public destroy(): void {
    super.destroy();

    if (this.total$) {
      this.total$.complete();
    }

    if (this.hasTop$) {
      this.hasTop$.complete();
    }

    if (this.hasBottom$) {
      this.hasBottom$.complete();
    }
  }

  protected executeUpdateQuery(): Promise<Observable<Message[]>> {
    this.paginationResult = null;
    this.clearEventFields();
    return this.resultHelper.executeUpdateQuery(this.minOffset, (this.maxOffset + this.batchSize) - this.minOffset);
  }

  protected getResultProcessor(): GraphResultProcessor {
    return new PagedGraphResultProcessor();
  }

  protected processUnknownMessage(data: any): void {
    if (data instanceof PaginationInformation) {
      this.processPaginationInformation(data);
    } else {
      super.processUnknownMessage(data);
    }
  }

  private executePagedUpdateQuery(offset: number, limit: number): Promise<Observable<Message[]>> {
    this.paginationResult = null;
    this.clearEventFields();
    return this.resultHelper.executeUpdateQuery(offset, limit);
  }

  private doFetchInternal(offset: number, limit: number): Promise<boolean> {
    let waitPromise: Promise<void> = Promise.resolve();

    if (this.processing && this.updatePromise) {
      console.warn('PagedObjectGraphResult: trying to fetch while update processing still ongoing... wait for update to finish!');

      waitPromise = this.updatePromise;
    }

    this.fetchPending = true;

    const promise = new Promise<boolean>((resolve, reject) => {
      // wait until the pending update/fetch and all other pending fetches (except this new one) have finished, then go on...
      waitPromise.then(() => {

        if ((this.maxOffset <= offset && !this.paginationResult.hasBottom) || (this.minOffset > offset && !this.paginationResult.hasTop)) {
          console.debug('PagedObjectGraphResult: no more top or bottom elements, ignore this fetch!');
          resolve(false);
          return;
        }

        this.processing = true;
        this.lastLimit = limit;
        this.lastOffset = offset;
        this.minOffset = Math.min(offset, this.minOffset);
        this.maxOffset = Math.max(this.maxOffset, offset);
        this.maxIndex = Math.max(this.maxIndex, this.maxOffset + limit);

        this.executePagedUpdateQuery(offset, limit).then((observable$: Observable<Message[]>) => {
          this.prepareAndStartProcessing(observable$, false, true, resolve);
        }).catch(error => {
          reject(error);
        });
      }).catch(error => {
        reject(error);
      });
    });

    // just cleanup after the fetch
    promise.then(() => this.fetchPending = false).catch(() => this.fetchPending = false);

    return promise;
  }

  private processPaginationInformation(paginationInfo: PaginationInformation): void {
    const hasTop = (paginationInfo.previousElementCount > 0 && this.minOffset > 0);
    this.paginationResult = new PaginationResult(paginationInfo.elementsFollow, hasTop, paginationInfo.totalElementCount);
    this.hasBottom$.next(this.paginationResult.hasBottom);
    this.hasTop$.next(this.paginationResult.hasTop);
    this.total$.next(this.paginationResult.totalElementCount);

    this.eventsSubject.next({
                              type: PAGINATION_INFO,
                              data: this.paginationResult
                            });
  }

  private alreadyFetched(offset: number, limit: number): boolean {
    return this.minOffset < offset && this.maxIndex > (offset + limit);
  }
}
