import { EMPTY, fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { CelumPropertiesProvider } from '../../core/configuration/celum-properties-provider';
import { EntityType } from '../../core/model/entity-type';
import { GraphEntity, GraphRelation } from '../../core/model/graph-entity';
import { Optional } from '../../core/model/optional';
import { AdditionalData } from '../model/additional-data';
import { ObjectGraphException } from '../model/error';
import { Message } from '../model/message';
import { MetaInfo } from '../model/meta-info';
import { DetailCountInfo, GraphResultProcessor } from './graph-result-processor';
import { ObjectGraphResultHelper } from './object-graph-result-helper';

const METAINFO = 'metaInfo';
const ADDITIONAL_DATA = 'additionalData';
const ERROR = 'error';
const UPDATE_TRIGGERED = 'updateTriggered';
const INITIAL_PROCESSING_COMPLETE = 'initialProcessingComplete';
const MESSAGE_PROCESS_COMPLETE = 'messageProcessComplete';
const START_PROCESSING = 'startProcessing';
const NEW_ENTITY = 'newEntity';
const NEW_RELATION = 'newRelation';
const NEW_DETAIL_COUNT = 'newDetailCount';

export class NewObjectGraphResult {

  protected processing = false;
  protected destroyed = false;
  protected eventsSubject = new Subject<{ type: string, data?: any }>();
  protected updatePromise: Promise<void>;

  private observable$: Observable<Message[]>;
  private updateInterval = 10000;
  private updateTimeout: any = 0;
  private processedMessages = 0;

  private entityIds: Set<string> = new Set();
  private entityList: GraphEntity[] = [];
  // key: id (<entityId>_<relationType>)
  private relations = new Set<GraphRelation>();
  private entityTypesMap: Map<string, GraphEntity[]> = new Map<string, GraphEntity[]>();
  private detailCounts: Map<string, number> = new Map<string, number>();

  private metaInfo: MetaInfo;
  private additionalData: AdditionalData;

  private subscription: Subscription;

  private error: any = null;
  private messageProcessComplete = false;
  private initialProcessingComplete = false;
  private startedProcessing = false;

  constructor(protected resultHelper: ObjectGraphResultHelper, private autoUpdate?: boolean, protected name?: string) {
    this.autoUpdate = CelumPropertiesProvider.properties.autoUpdateQueries && autoUpdate;
    resultHelper.init(this);
    this.observable$ = resultHelper.mapResults();
  }

  public initialize(): void {
    this.updatePromise = new Promise<void>(resolve => this.startProcessing(false, true, resolve));
  }

  public registerOnMessageProcessComplete(): Observable<void> {
    const currentValue$ = this.messageProcessComplete ? of(void 0) : EMPTY;
    const eventStream$ = this.eventsSubject.pipe(filter(event => event.type === MESSAGE_PROCESS_COMPLETE), map(() => void 0));
    return merge(currentValue$, eventStream$);
  }

  public registerOnInitialMessageProcessComplete(): Observable<void> {
    const eventStream$ = this.eventsSubject.pipe(filter(event => event.type === INITIAL_PROCESSING_COMPLETE), map(() => void 0));
    return this.initialProcessingComplete ? of(void 0) : eventStream$;
  }

  public registerStartProcessing(): Observable<void> {
    const currentValue$ = this.startedProcessing ? of(void 0) : EMPTY;
    const eventStream$ = this.eventsSubject.pipe(filter(event => event.type === START_PROCESSING), map(() => void 0));
    return merge(currentValue$, eventStream$);
  }

  public registerOnHeaderReceived(): Observable<void> {
    const currentValue$ = this.metaInfo ? of(void 0) : EMPTY;
    const eventStream$ = this.eventsSubject.pipe(filter(event => event.type === METAINFO), map(() => void 0));
    return merge(currentValue$, eventStream$);
  }

  public registerOnUpdateTriggered(): Observable<void> {
    return this.eventsSubject.pipe(filter(event => event.type === UPDATE_TRIGGERED), map(_ => void 0));
  }

  /**
   * Just forwards errors happening in the result subject.
   */
  public registerErrorCallback(): Observable<any> {
    const eventStream$ = this.eventsSubject.pipe(filter(event => event.type === ERROR), map(event => event.data));
    return this.error ? of(this.error) : eventStream$;
  }

  public setUpdateInterval(updateInterval: number): void {
    this.updateInterval = updateInterval;
  }

  /**
   * Force an update to happen regardless whether one is currently running. If a processing is currently running, it is simply dismissed.
   *
   * @returns promise which results once the update has finished processing
   */
  public forceUpdate(): Promise<void> {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.processing = false;

    return this.update();
  }

  public update(updateInterval?: number): Promise<void> {
    if (updateInterval) {
      this.updateInterval = updateInterval;
    }

    if (this.destroyed) {
      console.warn('ObjectGraphResult: ObjectGraph already destroyed', this.name);
      return Promise.reject<void>('No update, ObjectGraph already destroyed');
    }

    // tslint:disable-next-line:triple-equals
    if (this.update == null) {
      console.error('ObjectGraphResult: ObjectGraph is missing an update callback', this.name);
      return Promise.reject<void>('ObjectGraph is missing an update callback');
    }

    if (this.processing) {
      console.warn('ObjectGraphResult: trying to update ObjectGraph while older processing still ongoing.', this.name);
      return Promise.resolve();
    }

    this.processing = true;

    const newPromise = new Promise<void>((resolve, reject) => {
      // remember this promise as updatePromise only if an update is actually executed
      this.updatePromise = newPromise;

      this.executeUpdateQuery().then((o$: Observable<Message[]>) => {
        if (this.destroyed) {
          console.warn(`ObjectGraphResult: ObjectGraph for query "${this.name}" is already destroyed! Ignore this result and skip processing.`);
        } else {
          this.eventsSubject.next({ type: UPDATE_TRIGGERED });

          this.prepareAndStartProcessing(o$, true, false, resolve);
        }
      }).catch(error => {
        reject(error);
      });
    });

    return newPromise;
  }

  public isProcessing(): boolean {
    return this.processing;
  }

  public isInitialProcessingComplete(): boolean {
    return this.initialProcessingComplete;
  }

  /**
   * Get entities of a specific type.
   */
  public getEntitiesOfType<T extends GraphEntity>(entityType: EntityType): Optional<T[]> {
    // tslint:disable-next-line:triple-equals
    if (this.metaInfo != null) {
      if (this.metaInfo.isEntityRequested(entityType.id) && this.entityTypesMap.has(entityType.id)) {
        return Optional.of(this.entityTypesMap.get(entityType.id) as T[]);
      } else {
        let retOptional = Optional.ofNull<T[]>();

        entityType.inheritsFrom.forEach(superEntity => {
          const superEntityTypeKey: string = superEntity.id;

          if (this.metaInfo.isEntityRequested(superEntityTypeKey) && this.entityTypesMap.has(entityType.id)) {
            const entitySubject = this.entityTypesMap.get(superEntityTypeKey);

            retOptional = Optional.of(entitySubject as T[]);
          } else {
            retOptional = Optional.ofNull();
          }
        });

        return retOptional;
      }
    } else {
      return Optional.ofNull();
    }
  }

  public getRelations(): Set<GraphRelation> {
    return this.relations;
  }

  public getDetailCounts(): Map<string, number> {
    return this.detailCounts;
  }

  public getMetaInfo(): MetaInfo {
    return this.metaInfo;
  }

  public getAdditionalData<T>(): T {
    return this.additionalData && this.additionalData.data as T;
  }

  /**
   * Get the list of all entities for the processed result.
   * @returns GraphEntity[]
   */
  public getEntities(): GraphEntity[] {
    return [...this.entityList];
  }

  /**
   * Stop executing updates according to the update interval. Will _not_ affect already running updates!
   */
  public pauseUpdates(): void {
    clearTimeout(this.updateTimeout);
  }

  /**
   * Resume doing auto updates (if so configured) and issues the next update immediately if startImmediately is true.
   */
  public resumeUpdates(startImmediately: boolean = true): void {
    if (this.autoUpdate && startImmediately) {
      this.update().catch(e => console.error('ObjectGraphResult: Error on executing update query!', e));
    } else if (this.autoUpdate) {
      this.issueNextUpdate();
    }
  }

  public destroy(): void {
    this.destroyIntern();
  }

  protected executeUpdateQuery(): Promise<Observable<Message[]>> {
    this.clearEventFields();
    return this.resultHelper.executeUpdateQuery();
  }

  protected clearEventFields(): void {
    this.messageProcessComplete = false;
    this.startedProcessing = false;
    this.error = null;
  }

  protected prepareAndStartProcessing(observable$: Observable<Message[]>, updating: boolean, initial: boolean, resolve: (args?: any) => any): void {
    this.observable$ = observable$;
    this.startProcessing(updating, initial, resolve);
  }

  protected getResultProcessor(): GraphResultProcessor {
    return new GraphResultProcessor();
  }

  protected startProcessing(updating: boolean, initial: boolean = false, resolve?: (args?: any) => any): void {
    this.processing = true;
    const start: number = new Date().getTime();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (updating) {
      this.cleanup();
    }

    this.subscription = this.getResultProcessor().process(this.observable$)
                            .subscribe({
                                         next: messages => {
                                           messages.forEach(message => this.processMessage(message, updating));
                                         },
                                         error: (error: any) => this.handleErrorOnProcessing(error, resolve),
                                         complete: () => {
                                           this.processMessageStreamCompleted(start, initial, resolve);
                                           this.autoUpdate && this.issueNextUpdate();
                                         }
                                       });
  }

  protected processUnknownMessage(msgData: any): void {
    // no need
  }

  private processMessage(message: any, updating: boolean): void {
    if (message instanceof MetaInfo) {
      this.metaInfo = message;

      if (!updating) {
        this.eventsSubject.next({ type: METAINFO });
      }

      this.startedProcessing = true;
      this.eventsSubject.next({ type: START_PROCESSING });
    } else if (message instanceof GraphEntity) {
      this.processEntity(message);
      this.eventsSubject.next({ type: NEW_ENTITY });
    } else if (message instanceof GraphRelation) {
      this.processRelation(message);
      this.eventsSubject.next({ type: NEW_RELATION });
    } else if (isDetailCount(message)) {
      this.processDetailCountMessage(message);
      this.eventsSubject.next({ type: NEW_DETAIL_COUNT });
    } else if (message instanceof AdditionalData) {
      this.additionalData = message;
      this.eventsSubject.next({ type: ADDITIONAL_DATA });
    } else {
      this.processUnknownMessage(message);
    }

    this.processedMessages++;
  }

  private handleErrorOnProcessing(error: any, resolve: (args?: any) => any): void {
    console.error('ObjectGraphResult: error on processing query ' + this.name, error);

    if (CelumPropertiesProvider.properties.logPerformanceMeasurements) {
      console.log(`Performance: Processed ${this.processedMessages} message before error.`);
    }

    if (!isObjectGraphError(error)) {
      // tslint:disable-next-line:no-parameter-reassignment
      error = new ObjectGraphException('ERRORS.OBJECT_GRAPH_RESULT.ERROR_LOADING');
    }

    this.processedMessages = 0;
    this.processing = false;
    this.updatePromise = null;
    resolve && resolve(false); // just make sure to inform listeners that the processing has finished
    this.error = error;
    this.eventsSubject.next({
                              type: ERROR,
                              data: error
                            });

    this.autoUpdate && this.issueNextUpdate();
  }

  private issueNextUpdate(): void {
    if (!this.processing) {
      this.updateTimeout = setTimeout(() => document && document.hidden ? this.handleInactiveWindow() :
                                            this.update().catch(e =>
                                                                  console.error('ObjectGraphResult: Error executing update!', e)), this.updateInterval);
    }
  }

  private handleInactiveWindow(): void {
    this.pauseUpdates();
    console.debug(`ObjectGraphResult: AutoUpdate for query '${this.name}' was issued but window is not active.`);

    fromEvent(document, 'visibilitychange').pipe(filter(() => !document.hidden), take(1)).subscribe(() => {
      if (!this.destroyed) {
        console.debug(`ObjectGraphResult: Update of query '${this.name}' was issued while window was not active. Execute update now!`);
        this.resumeUpdates();
      }
    });
  }

  private cleanup(): void {
    this.entityList = [];
    this.entityIds = new Set();
    this.relations = new Set<GraphRelation>();
    this.entityTypesMap = new Map<string, GraphEntity[]>();
    this.detailCounts = new Map<string, number>();
    this.metaInfo = null;
  }

  private processMessageStreamCompleted(start: number, initial: boolean, resolve: (args?: any) => any): void {
    if (CelumPropertiesProvider.properties.logPerformanceMeasurements) {
      const end = new Date().getTime();

      console.log(`Performance: processing all data (${this.processedMessages} messages) from query ${this.name} took ${(end - start)}ms`);
    }

    this.processedMessages = 0;
    this.initialProcessingComplete = true;

    if (initial) {
      // notify listeners about finished processing of initial result
      this.eventsSubject.next({ type: INITIAL_PROCESSING_COMPLETE });
    }

    if (CelumPropertiesProvider.properties.logCommunication) {
      console.debug('ObjectGraphResult: object graph onComplete was called');
    }

    this.processing = false;
    this.updatePromise = null;
    resolve && resolve(true);

    this.messageProcessComplete = true;
    this.eventsSubject.next({ type: MESSAGE_PROCESS_COMPLETE });
  }

  private processEntity(entityDto: GraphEntity): void {
    if (this.entityIds.has(entityDto.id)) {
      return;
    }

    this.entityList.push(entityDto);
    this.entityIds.add(entityDto.id);

    const entityType = entityDto.entityType;
    const classNames = [entityType.id];

    entityType.inheritsFrom.forEach(superType => {
      classNames.push(superType.id);
    });

    classNames.forEach(className => {
      if (this.entityTypesMap.has(className)) {
        this.entityTypesMap.get(className).push(entityDto);
      } else {
        const entitiesList = [entityDto];
        this.entityTypesMap.set(className, entitiesList);
      }
    });
  }

  private processRelation(relationDto: GraphRelation): void {
    this.relations.add(relationDto);
  }

  private processDetailCountMessage(detailCount: DetailCountInfo): void {
    this.detailCounts.set(detailCount.id, detailCount.count);
  }

  private destroyIntern(): void {
    if (this.destroyed) {
      return;
    }

    clearTimeout(this.updateTimeout);
    this.updatePromise = null;
    this.subscription && this.subscription.unsubscribe();
    this.subscription = null;
    this.destroyed = true;
    this.eventsSubject.complete();
    this.resultHelper.destroy();
  }
}

function isDetailCount(info: DetailCountInfo): info is DetailCountInfo {
  return info.id !== undefined && info.count !== undefined;
}

function isObjectGraphError(target: any | ObjectGraphException): target is ObjectGraphException {
  return target.prototype.messageKey !== undefined;
}
